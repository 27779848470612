//import { createSlice, current } from "@reduxjs/toolkit";
import { nHApi } from 'v2/src/lib/services/public/nHApi';
import { USER_TYPES } from 'v2/src/config/appConfig';
//---------------------------------------------------------

export const profileApi = nHApi.injectEndpoints({ endpoints: builder => ({
  userProfile: builder.query({
    query: (params) => {
      let userId =  params?.userId;
      if(!userId) {
        //userId = this.state.
      }
      let arrRelations = params.arrRelations ||
        [ 'user', 'role', 'language', //'provider', 'patient',
          'addresses',
        ];
      let relationQS = '?';
      arrRelations.forEach(pRel => {
        relationQS += 'relations[]='+pRel+',';
      });
      let aUrl = '/profile/' + params.profileId + relationQS;

      return { url: aUrl};
      //return { url: aUrl, params: { v1, v2 },};
    },
  }),
  userDetails: builder.query({//Almost same as userProfileDetail below
    query: (params) => {
      //let userId =  params?.userId;
      //if(!userId) {//userId = this.state. }
      let profId = params?.profileId, userType = params?.userType,
        strComps='', arrComps=['user', 'role', 'language'];
      if(userType === USER_TYPES.provider) {
        arrComps.push('provider');
      } else {//if(userType === USER_TYPES.patient) {
        arrComps = arrComps.concat(['addresses', 'patient']);
      }
      if(arrComps?.length) {
        arrComps = arrComps.map(cmp => 'relations[]=' + cmp);
        strComps = '?' + arrComps.join('&');
      }
      //let aUrl = '/profile/' + profId + '?relations[]=user&relations[]=role&relations[]=language&relations[]=provider&relations[]=patient&relations[]=addresses';
      let aUrl = '/profile/' + profId + strComps;

      return { url: aUrl};
    },
  }),
  setUser: builder.mutation({
    query: ({userId, inParams}) => {
      return {
        //first_name, middle_name, last_name, phone
        url: '/user/' + userId,
        method: 'POST',
        body: inParams,
      }
    },
  }),
  userProfileList: builder.query({
    query: (params) => {
      //profiles?user_id=2&relations[]=role&relations[]=provider&relations[]=addresses
      if(params?.profileId) {
        userRole = params?.profileId;
      }
      let aUrl = '/profiles';

      return { url: aUrl, params: params};
    },
  }),
  userProfileDetail: builder.query({
    query: (params) => {//console.log('uPDParams', params);
      let strComps = '';
      const defArrComps = [
        'user', 'role', 'language', 'addresses',
        'patient', //'provider'
      ];
      let profId = params?.profileId;
      let arrComps = params?.arrComps || defArrComps;
      if(arrComps?.length) {
        arrComps = arrComps.map(cmp => 'relations[]=' + cmp);
        strComps = '?' + arrComps.join('&');
      }
      let aUrl = '/profile/' + profId + strComps;
      return { url: aUrl};
      //return { url: aUrl, params: params};
    },
  }),
  setUserProfile: builder.mutation({
    query: ({profileId, inParams}) => {
      return {
        //profile_pic, date_of_birth, gender, language_id, timezone_id
        url: '/profile/' + profileId,//params.profileId,
        method: 'POST',
        body: inParams,
      }
    },
  }),
  //---------------------------------------------------------

  /*setNSUserDetails: builder.mutation({
    query: ({profileId, inParams}) => {
      return {
        //profile_pic, date_of_birth, gender, language_id, timezone_id
        url: '/profile/' + profileId,//params.profileId,
        method: 'POST',
        body: inParams,
      }
    },
  }),*/

}), });

export const {
  useUserProfileQuery, useLazyUserProfileQuery, useLazyUserDetailsQuery,
  useUserProfileDetailQuery, useLazyUserProfileDetailQuery,
  useUserProfileListQuery, useSetUserMutation, useSetUserProfileMutation,
} = profileApi;
//---------------------------------------------------------

