import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { Link, useNavigate } from "react-router-dom";
import { USER_TYPES, //DEF_LANGUAGE_ID, DEF_COUNTRY_ID, DEF_PROFILE_PIC_URL,
} from 'v2/src/config/appConfig';
import { fGetLocalSt, fSetLocalSt, } from 'v2/src/lib/appUtils';
//---------------------------------------------------------
export const xfSaveProfilePic = async(fFx, profId, actParams, fCallback, errCb) => {
  //console.log('actProfPicParams_'+profId, actParams);
  let upProfPicResp;
  try {
    //apiResp = await fFx(aParams);//apiResp = {'result': true};
    upProfPicResp = await fFx({profileId: profId, inParams: actParams});
    //upProfPicResp = {'result': true};
    //console.log('upProfPicResp', {actParams, upProfPicResp});

    //if(upProfPicResp.result === true) {
    if(upProfPicResp?.data?.status === true) {
      upProfPicResp = {'result': true};
      //if(!upProfPicResp?.data.hasOwnProperty('data')) {
      //  upProfPicResp['data'] = upProfPicResp;
      //}
    } else {
      upProfPicResp = { result: false, error: {code: 'profPicUpFail', desc: ''}};
    }
  } catch(err) {
    console.log('ErrProfPicUp', {actParams, err});
    upProfPicResp = { result: false, error: {code: 'profPicUpFail2', desc: err}};
  }
  return upProfPicResp;
};

export const fCallNHApi = async(aHdText, fFx, aParams, fCallback, errCb) => {
  console.log('apiParams_'+aHdText, {aParams, frmData: Object.fromEntries(aParams?.inParams)});
  //console.log('fSaveUserAddressIns', Object.fromEntries(actParams));
  let apiResp;
  try {
    apiResp = await fFx(aParams);//apiResp = {'result': true};
    //console.log(aHdText+'_ApiResp', {aParams, apiResp});

    //if(apiResp.result === true) {
    if(apiResp?.data?.status === true) {
      apiResp = {'result': true};
      if(!apiResp?.data?.hasOwnProperty('data')) {
        apiResp['data'] = apiResp;
      }
    } else {
      apiResp = { result: false, error: {code: aHdText+'Fail', desc: ''}};
    }
  } catch(err) {
    console.log(aHdText+'Err', {aParams, err});
    apiResp = { result: false, error: {code: aHdText+'Fail2', desc: err}};
  }
  return apiResp;
};

export const fGetUserProfileUpData = (fData, uType) => {
  let resp;// = {};
  //NotAllowed->eMail: userType: userRoleId: userRole: profileId: gender:
  //firstName: FirstName, middleName: MiddleName, lastName: LastName,
  //dispName: , //profilePic:
  //address? [Address1, Address2, City, Province, ZIPCode, CountryID]//profilePic
  if(uType && fData.length) {
    switch (uType) {
      case USER_TYPES.provider:
        /**let provName = objProfData?.user?.first_name + ' ';
        provName += (objProfData?.user?.middle_name || '') + ' ';
        provName += (objProfData?.user?.last_name) || ''; */
        /*resp = {
          dispName: fData,
          contactNum: fData?.contactNum,
        };*/
        break;
      case USER_TYPES.patient:
        resp = {
          contactNum: fData.contactNum,
          tzId: fData.LocalTimeZone,
          dob: fData.DateOfBirth,
          langId: fData,//P
          langCode: fData,//P
        };
        break;
    }
  }//return Object?.keys(resp)?.length ? resp : null
  return resp;
};

export const fGetUserCurrDocImgListInf = (selPDoc, uDocsInf=[]) => {
  let resp = {};// = [];
  if(uDocsInf?.length) {
    let currIdx, i=0, arrUrls = [];
    uDocsInf.forEach((uD) => {
      if(uD.id === selPDoc.id) { currIdx = i; } i++;
      uD?.docApiData?.file_url && arrUrls.push(uD.docApiData.file_url);
      uD?.hasView && uD?.docFormData?.docFile &&
      arrUrls.push(URL.createObjectURL(uD.docFormData.docFile));
    });
    resp = { currIdx: currIdx, arrUrls: arrUrls };
  }
  return resp;
};

export const InsuranceStatusText = ({ status=0 }) => {
  const docStatMeta = {
    'resubmission_requested' : { css: 'badge-soft-purple', sTxt: 'For Approval'},
    'pending' :  { css: 'badge-soft-purple', sTxt: 'Pending'},
    'approved' : { css: 'badge-soft-success', sTxt: 'Approved'},
    'rejected' : { css: 'badge-soft-danger', sTxt: 'Rejected'},
  };
  return (
    <span className={'virtualvisitbadge ml-0 badge badge-md '+docStatMeta[status]['css']}>
      { docStatMeta[status]['sTxt'] }
    </span>
  )
};

export const fCalcProfileIdDocsInf = (maxDocCount, savedIdDocsData=[]) => {
  //ToBeCalled ONLY after fetch/Refetch userIdDocsApiData
  let loopCnt=0, savedDocCnt = savedIdDocsData?.length || 0;
  let respIdDocInf = {
    maxDocCount: maxDocCount, savedDocCount: savedDocCnt,
    shownIdDocCount: 0, arrIdDocs: []
  };
  //setTimeout(() => {
    if(savedDocCnt) {
      savedIdDocsData.forEach(uDocData => {
        respIdDocInf.arrIdDocs[loopCnt] = {
          id: 'uIdDoc_' + uDocData?.id, dispOrder: loopCnt,
          hasData: true, docApiData: uDocData,
          hasView: true, errCode: null, hasErr: false, arrErrMsg: [],
        };
        respIdDocInf.shownIdDocCount++;
        loopCnt++;
      });
    }

    //let remainNewDocs = loopCnt;
    for(; loopCnt < maxDocCount; loopCnt++) {
      const isViewable = loopCnt === 0;
      isViewable && respIdDocInf.shownIdDocCount++;

      respIdDocInf.arrIdDocs[loopCnt] = {
        id: 'new_'+loopCnt, dispOrder: loopCnt,
        hasData: false, docFormData: { docTypeId: null, docFile: null},//undefined
        hasView: isViewable, errCode: null, hasErr: false, arrErrMsg: [],
      };
    }
    //console.log('respIdDocInfCalc', {maxDocCount, savedIdDocsData, respIdDocInf});
  //}, 1000);

    return respIdDocInf;
};