import { useEffect, useState, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
//import PhoneInput from "react-phone-number-input";
//import {default as PhInput} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { usePlacesWidget } from "react-google-autocomplete";
import Swal from "sweetalert2";
import moment from "moment";

import { useTranslation } from 'react-i18next';
import './icon.css';
import { //AWS_BUCKET_DOCUMENTS, AWS_BUCKET_PROFILES, AWS_BUCKET_SERVICES,
  GOOGLE_PLACES_API_KEY, USERTYPE, FLG_USE_SWAL, NIU_SA_API_URL,
} from 'constants';
import { //fGetTrimmedText, fGetUrlFileName,
  //fGetArrTimeZone, fGetCountryProvince,
  fGetArrTimeZone2, fGetCountryProvince2, getProvinceFromCountryId,
  fGetHMFormat2, fParseGglPlaces, fFormatGglAddressComps,
} from 'utils';
//import { fCallAxApi } from 'api/axios';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useAuth from 'hooks/useAuth';
import { ContainerFluid, PageWrapper, TableTitle } from 'components/table/Tables';
//import GPlacesAddressField from 'components/gPlacesAddressField';
import RingLoading from 'components/lottie/RingLoading';
import TimeZoneSelect from 'components/time/Timezone';
import TimeZoneSelect2 from 'components/time/Timezone2';
import ScheduleSelect from 'components/time/Hours';
import LanguageDropdown from 'components/LanguageDropdown';
import Flasher from 'components/messageBox/Flasher';
import PageMessage from 'components/messageBox/PageMessage';
import PatientProfileSchema from 'validation/patientProfile';
import ProviderProfileSchema from 'validation/providerProfile'
import i18n from 'i18n';
import ProfileIdDocument from './ProfileIdDocument';
import Insurance from 'pages/patient/insurance/Insurance';
//---------------------------------------------------------
//console.log('NIU_SA_API_URL', NIU_SA_API_URL);

export default function Profile3() {
  const { auth, setAuth } = useAuth()
  //console.log('proAuth', auth);
  const axiosPrivate = useAxiosPrivate()
  const { t: error_message } = useTranslation('error_message')
  const { t: comp_profile } = useTranslation('comp_profile')
  const defCountryId = 232;//USA
  const defCountryCode = 'US';
  const defCountryName = 'United States';
  const dummyDOB = '1920-01-01';
  // const effectRun = useRef(false);

  const [stPgMsg, setStPgMsg] = useState('');
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const fResetProcessing = (flgClearPgMsg=false) => {
    setStProcessing(false);
    //if(flgClearPgMsg) {
      setStPgMsg(''); setStPgHasErr(false);
    //}
  };
  const [profile, setProfile] = useState({
    ...auth,
    hours_sun_end: '20',    hours_sun_start: '8',
    hours_mon_end: '20',    hours_mon_start: '8',
    hours_tue_end: '20',    hours_tue_start: '8',
    hours_wed_end: '20',    hours_wed_start: '8',
    hours_thu_end: '20',    hours_thu_start: '8',
    hours_fri_end: '20',    hours_fri_start: '8',
    hours_sat_end: '20',    hours_sat_start: '8',
    picture: (auth.userType === USERTYPE.patient ? '' : '') + 'Default.jpg',
    contact_info: '',
    practice: '',
    provider_description: '',
    provider_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    local_time_zone: '',
    address_line_1: '',
    address_line_2: '',
    zip_code: '',
    country_city_id: '',
    city: '',
    province: '',
    language: '',
    has_insurance: '',
    country_id: defCountryId, //country_id: '0',
    date_of_birth: dummyDOB, //'2000-01-01' // ''
    picturefile: '',
    facebook: '',
    twitter: '',
    instagram: '',
    othersocial: '',
    email: auth.email,
  });
  const [oldProfile, setOldProfile] = useState({})
  //const [disableForm, setDisableForm] = useState(true)
  const [disableForm, setDisableForm] = useState(false)
  const flgEditable = !disableForm;
  const flgUseTimezoneScheme2 = true;
  let arrTz2;

  const [isLoading, setIsLoading] = useState(true)
  const [timeZone, setTimeZone] = useState('+00:00')
  const [countries, setCountries] = useState([])
  const [stFlgProvincesFetched, setStFlgProvincesFetched] = useState(false);
  const [apiProvinceList, setApiProvinceList] = useState([])
  const [countryProvinceList, setCountryProvinceList] = useState([])
  const [cities, setCities] = useState([])
  const [cityActive, setCityActive] = useState(false)
  const [imagepreview, setImagePreview] = useState(false)
  const imgRef = useRef()

  const [hours, setHours] = useState({
    HoursMonStart: 8,    HoursMonEnd: 20,
    HoursTueStart: 8,    HoursTueEnd: 20,
    HoursWedStart: 8,    HoursWedEnd: 20,
    HoursThuStart: 8,    HoursThuEnd: 20,
    HoursFriStart: 8,    HoursFriEnd: 20,
    HoursSatStart: 8,    HoursSatEnd: 20,
    HoursSunStart: 8,    HoursSunEnd: 20,
  })
  const [oldHours, setOldHours] = useState({
    HoursMonStart: 8,    HoursMonEnd: 20,
    HoursTueStart: 8,    HoursTueEnd: 20,
    HoursWedStart: 8,    HoursWedEnd: 20,
    HoursThuStart: 8,    HoursThuEnd: 20,
    HoursFriStart: 8,    HoursFriEnd: 20,
    HoursSatStart: 8,    HoursSatEnd: 20,
    HoursSunStart: 8,    HoursSunEnd: 20,
  })
  function dateFormat(strDate) {
    //return moment(strDate).format('YYYY-MM-DD')
    //const objDate = new Date(strDate);//console.log('ssss', objDate.toString(), strDate);
    strDate = (new Date(strDate)).toISOString();
    //strDate += 'T01:10:00.000Z';
    //return moment(strDate).format('MMM-DD-YYYY');
    return moment(strDate).format('MM/DD/YYYY');
  }

  const {
    register: PatientRegister,
    trigger: PatientProfileTrigger,
    setValue: PatientProfileSetValue,
    getValues: PatientProfileGetValues,
    control: PatientControl,
    formState: { errors: PatientProfileError, isValid: PatientProfileIsValid },
  } = useForm({
    resolver: yupResolver(PatientProfileSchema),
  })

  const {
    trigger: ProviderProfileTrigger,
    setValue: ProviderProfileSetValue,
    getValues: ProviderProfileGetValues,
    formState: { errors: ProviderProfileError, isValid: ProviderProfileIsValid },
  } = useForm({
    resolver: yupResolver(ProviderProfileSchema),
  })

  const handleLanguageChange = (selectedOption) => {
    PatientProfileSetValue('Language', selectedOption.value)
    //PatientProfileTrigger('Language')
    setProfile((prev) => ({ ...prev, language: selectedOption.value }))
  }

  //const arrStates = fGetCountryProvince(defCountryId);
  const arrStates = fGetCountryProvince2(defCountryId);
  if(flgUseTimezoneScheme2) {
    arrTz2 = fGetArrTimeZone2(defCountryId, defCountryCode);
  }//console.log('arrTz2', arrTz2);

  const fAutoPopulateAddress = async (tEl, plInf, langCode=null) => {
    let addrComps, tzInf, cTZs; console.log('Pl', plInf);

    addrComps = fParseGglPlaces(plInf, GOOGLE_PLACES_API_KEY, langCode);
    console.log('addrComps', addrComps);
    if(addrComps?.selAddr) {
      let nAddrComps = fFormatGglAddressComps(addrComps);
      console.log('nAddrComps', nAddrComps);
      if(nAddrComps.nProvince?.length) {//tEl
        //elAddr1, elAddr2, elCity, selProvince, elZip, country_idtx, selTZ
        const elAddr1 = document.getElementById('elAddr1');
        const elAddr2 = document.getElementById('elAddr2');
        const elCity = document.getElementById('elCity');
        const elProvince = document.getElementById('selProvince');
        //const elCountry = document.getElementById('country_idtx');
        const elZip = document.getElementById('elZip');
        const elTz = document.getElementById('selTz');

        //console.log('stProfile', profile);
        if(elAddr1 && nAddrComps.nAddr1?.length) {
          elAddr1.value = nAddrComps.nAddr1;
          PatientProfileSetValue('Address1', nAddrComps.nAddr1);
          //PatientProfileTrigger('Address1');
          fSetInputChange('address_line_1', nAddrComps.nAddr1);
        }
        if(elAddr2) {// && nAddrComps.nAddr2?.length) {
          elAddr2.value = nAddrComps.nAddr2;
          PatientProfileSetValue('Address2', nAddrComps.nAddr2);
          //PatientProfileTrigger('Address2');
          fSetInputChange('address_line_2', nAddrComps.nAddr2);
        }
        if(elCity) {// && nAddrComps.nCity?.length) {
          elCity.value = nAddrComps.nCity;
          PatientProfileSetValue('City', nAddrComps.nCity);
          //PatientProfileTrigger('City');
          fSetInputChange('city', nAddrComps.nCity);
        }
        if(elProvince) {// && nAddrComps.nProvince?.length) {
          elProvince.value = nAddrComps.nProvince;
          //elProvince.selectedIndex = province?.long_name;
          PatientProfileSetValue('Province', nAddrComps.nProvince);
          //PatientProfileTrigger('Province');
          fSetInputChange('province', nAddrComps.nProvince);

          let stTz, selProvinceInf = arrStates.filter(st => st.province === nAddrComps.nProvince);
          //console.log('selProvinceInf '+nAddrComps.nProvince, {selProvinceInf, arrStates});
          if(elTz && selProvinceInf?.length) {
            selProvinceInf = selProvinceInf[0];
            //console.log('selProvinceInf', selProvinceInf);
            stTz = selProvinceInf?.primaryTz;
          }
          if(stTz && arrTz2?.length) {
            let stTzInf = arrTz2.filter(tz => tz.tzCode === stTz);
            //console.log('selProvinceInf', {stTz, stTzInf, selProvinceInf});
            if(stTzInf?.length) {
              stTz = fGetHMFormat2(stTzInf[0]['utcOffsetMinute']);
              //console.log('stTz2', stTz);
              elTz.value = stTz;
              PatientProfileSetValue('LocalTimeZone', stTz);
              //PatientProfileTrigger('LocalTimeZone');
              fSetInputChange('local_time_zone', stTz);
            }
          }
        }
        if(elZip) {// && nAddrComps.nZip?.length) {
          elZip.value = nAddrComps.nZip;
          PatientProfileSetValue('ZIPCode', nAddrComps.nZip);
          //PatientProfileTrigger('ZIPCode');
          fSetInputChange('zip_code', nAddrComps.nZip);
        }

        /*if(10 && addrComps?.tzApiUrl) {
          tzInf = await fCallAxApi(addrComps.tzApiUrl);
          //if(tzInf?.status === 'OK') {}
        }
        cTZs = fGetArrTimeZone(defCountryCode);
        if(cTZs?.length) {
          let addrTz = [];
          if(nAddrComps?.nCity?.length) {
            addrTz = cTZs.filter(tz => tz?.name.indexOf(nAddrComps.nCity) > -1);
          }
          if(!addrTz && nAddrComps.nProvince?.length) {
            addrTz = cTZs.filter(tz => tz?.name.indexOf(nAddrComps.nProvince) > -1);
          }
          console.log('addrTz', addrTz);
          if(addrTz.length) {
            //elTz.value = addrTz[0]?.name;
            //PatientProfileSetValue('', nAddrComps.);
            //PatientProfileTrigger('');
            //fSetInputChange('local_time_zone', nAddrComps.?);
          }
        }*/
      }
    }
  };

  const { ref: addrRef } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      const inEl = addrRef.current;
      if(inEl && place) { fAutoPopulateAddress(inEl, place, profile?.language); }
    },
    options: {//fields:[], // libraries: [],
      //If nothing is specified, all types are returned
      language: profile.language,
      //types: ['(regions)'],//food|restaurant, 'establishment',
      types: ['street_number', 'street_address', 'postal_code'],// 'cities', 'utc_offset'
      inputtype: 'textquery',
      componentRestrictions: { country: defCountryCode },
    },
  });
  const dobRef = useRef();
  const elDatePickerId = 'date_of_birth';
  useEffect(() => {
    //jqueryFn();
    $('#'+elDatePickerId).datepicker({
      changeMonth: true,  changeYear: true, 
      placeholder: 'mm-dd-yyyy', //dateFormat: "M-dd-yy",
      yearRange: "-90:+00", //yearRange: "-90:-10",
      //defaultDate: '',//valueDefault: null,
      maxDate: -1, //maxDate: new Date(),//selected, beforeShow 
      //showOn: 'both',//"button",
      //buttonImage: "images/calendar.gif",
      //buttonImageOnly: true, buttonText: ''
      onSelect: (dtVal, inst) => {
        const inDate = dtVal;
        const outDate = moment(inDate).format('YYYY-MM-DD');
        PatientProfileSetValue('DateOfBirth', outDate)
        PatientProfileTrigger('DateOfBirth')
        fSetInputChange(elDatePickerId, outDate);
        setTimeout(()=> { $(this).datepicker('setDate', new Date(outDate)); }, 20);
      }
    }).on('dp.change', () => { $(this).trigger('blur'); });

    /*if(0) {console.log('dob', profile?.date_of_birth);
      const objDobDt =  new Date(profile?.date_of_birth);
      setTimeout(()=> {
        $('#'+elDatePickerId).datepicker('setDate', new Date(objDobDt));
      }, 100);
    }*/
    //return () => { $('#'+elDatePickerId).off('datepicker'); };
  }, [dobRef, setProfile]);

  const fCheckIsNewProfile = (profData) => {
    /*return (totalUploadedDocuments <= 0 || !patientDetails.Data[0]?.address_line_1 ||
        !patientDetails.Data[0]?.address_line_1?.length
        ||! DOB
     */
  };

  const handleImageInputChange = (e) => {
    const [file] = e.target.files;
    const pImf = document.getElementById('imProfilePic');
    if(1) {
      fSaveProfilePic(file);
      pImf.src = URL.createObjectURL(file);
      //pImf.onload = function() { URL.revokeObjectURL(pImf.src); }
    } else {
      setProfile({ ...profile, picturefile: file, });//withUseEffect[profile]
    }
  }

  const fSaveProfilePic = async (fl) => {
    if(typeof fl === 'object') {
      const formData = new FormData();
      //ReqFields: Email, FirstName, LastName, ContactInfo,
      // Address1, Address2, ZIPCode, Province, DateOfBirth,
      // LocalTimeZone, CountryID, City
      formData.append('Email', auth.email);
      formData.append('FirstName', auth.first_name);
      formData.append('LastName', auth.last_name);
      formData.append('ContactInfo', auth.contact_info);
      formData.append('Address1', auth.address_line_1 || '');
      formData.append('Address2', auth.address_line_2 || '');
      formData.append('ZIPCode', auth.zip_code || '');
      formData.append('Province', auth.province || '');
      formData.append('DateOfBirth', auth.date_of_birth || dummyDOB);
      formData.append('LocalTimeZone', auth.local_time_zone || '');
      formData.append('CountryID', auth.country_id || defCountryId);
      formData.append('City', auth.city || '');

      formData.append('Image', fl, 'profile_pic');

      await axiosPrivate
        .post('updatePatientDetails', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }) .then((res) => {
          const { Status, Data: data = [], Message } = res.data;
          if(Status) {
            //setAuth((prev) => ({ ...prev, ...profile }));
            //setOldProfile(profile);
            getProfileDetails();
          }
        }).catch((err) => { console.error(err); });
    }
  }
 
  async function handleSubmit() {
    //console.log(profile.language)
    const formData = new FormData();
    let frmVals = Object.fromEntries(formData);

    if (auth.userType === USERTYPE.patient) {
      /*patient validation*/
      let isPatientProfileValid = false

      /*const first_name = profile.first_name
      const middle_name = profile.middle_name || ''
      const last_name = profile.last_name
      const contact_info = profile.contact_info
      const address_line_1 = profile.address_line_1
      const address_line_2 = profile.address_line_2
      const zip_code = profile.zip_code
      const city = profile.city
      const country_city_id = profile.country_id || defCountryId
      const province = profile.province
      const language = profile.language
      const date_of_birth = profile.date_of_birth
      const local_time_zone = profile.local_time_zone*/

      /* Check the user type and trigger the validation */
      PatientProfileSetValue('FirstName', profile.first_name);
      PatientProfileSetValue('MiddleName', profile.middle_name || '');
      PatientProfileSetValue('LastName', profile.last_name);
      PatientProfileSetValue('ContactInfo', profile.contact_info);
      PatientProfileSetValue('Address1', profile.address_line_1);
      PatientProfileSetValue('Address2', profile.address_line_2);
      PatientProfileSetValue('ZIPCode', profile.zip_code);
      PatientProfileSetValue('Province', profile.province);
      PatientProfileSetValue('Language', profile.language);
      PatientProfileSetValue('City', profile.city);
      PatientProfileSetValue('CountryID', profile.country_id || defCountryId);
      PatientProfileSetValue('DateOfBirth', profile.date_of_birth);
      PatientProfileSetValue('LocalTimeZone', profile.local_time_zone);

      const result = await PatientProfileTrigger()
      isPatientProfileValid = result && Object.keys(result).length === 0

      if(!isPatientProfileValid) { return false; }

      formData.append('Email', auth.email)
      formData.append('FirstName', profile.first_name || '')
      formData.append('MiddleName', profile.middle_name || '')
      formData.append('LastName', profile.last_name || '')
      formData.append('ContactInfo', profile.contact_info || '')
      formData.append('Address1', profile.address_line_1 || '')
      formData.append('Address2', profile.address_line_2 || '')
      formData.append('ZIPCode', profile.zip_code || '')
      formData.append('Province', profile.province || '')
      formData.append('Language', profile.language || '')
      //formData.append('CountryID', profile.country_id || '')
      formData.append('CountryID', defCountryId)
      formData.append('City', profile.city || '')
      formData.append('DateOfBirth', moment(profile.date_of_birth).format('YYYY-MM-DD') || '')
      formData.append('LocalTimeZone', profile.local_time_zone || '')
    } else {
      /*Provider validation*/
      let isProviderProfileValid = false

      const provider_name = profile.provider_name
      const provider_description = profile.provider_description
      const practice = profile.practice
      const contact_info = profile.contact_info

      /* Check the user type and trigger the validation */
      ProviderProfileSetValue('provider_name', provider_name)
      ProviderProfileSetValue('ProviderDescription', provider_description)
      ProviderProfileSetValue('Practice', practice)
      ProviderProfileSetValue('ContactInfo', contact_info)

      const result = await ProviderProfileTrigger()
      isProviderProfileValid = result && Object.keys(result).length === 0

      if(!isProviderProfileValid) {
        setStPgHasErr(true);
        return false;
      }

      formData.append('Email', auth.email)
      formData.append('Name', profile.provider_name)
      formData.append('ProviderDescription', profile.provider_description)
      formData.append('Practice', profile.practice)
      formData.append('ContactInfo', profile.contact_info)
      for (let key in hours) {
        if (hours.hasOwnProperty(key)) {
          formData.append(key, hours[key])
        }
      }
    }

    if (typeof profile.picturefile === 'object') {
      formData.append('Image', profile.picturefile, 'profile_pic')
    }
    let profile_endpoint2 = auth.userType === USERTYPE.provider ? 'providerUpdateDetails' : auth.userType === USERTYPE.patient ? 'updatePatientDetails' : 'none'

    //console.log('formData', frmVals);
    setStProcessing(true);
    await axiosPrivate
      .post(profile_endpoint2, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: function (ProgressEvent) {
          // console.log(
          //   "uploadprogress: " +
          //     (ProgressEvent.loaded / ProgressEvent.total) * 100 +
          //     "%"
          // );
        },
      })
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data
        if (Status) {
          setAuth((prev) => ({ ...prev, ...profile }))
          setOldProfile(profile)
          if(FLG_USE_SWAL) {
            Swal.fire({ icon: 'success',
              text: comp_profile('profile_update_successfull') });
          } else {
            setStPgMsg(comp_profile('profile_update_successfull'));
          }
          !flgEditable && setDisableForm(!disableForm)
          if (auth.userType === USERTYPE.patient) {
            getProfileDetails()
          } else {
            let updateHours = {}
            for (let key in hours) {
              if (hours.hasOwnProperty(key)) {
                updateHours[key] = hours[key]
              }
            }
            setOldHours(updateHours)
          }
        } else {
          Swal.fire({ icon: 'error', text: Message })
          throw new Error(Message)
        }
      })
      .catch((err) => { console.error(err) });
    setStProcessing(false);
  }

  function triggerFileInput() {
    if(imgRef.current) { imgRef.current.click() }
  }

  function handleCancelEdit() {
    setImagePreview(false)
    setDisableForm((prev) => !prev)
    setProfile(oldProfile)
    setHours(oldHours)
  }

  function handleInputChange(e) {
    const name = e.target.name
    const value = e.target.value
    setProfile((prev) => ({ ...prev, [name]: value }))
  }

  function fSetInputChange(iKey, iVal) {
    setProfile((prev) => ({ ...prev, [iKey]: iVal }))
  }

  /*function FormTextField({ label, name, value, onChange }) {
    return (
      <div className="form-group row">
        <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
          {label}
        </label>
        <div className="col-sm-10">
          <input disabled={disableForm} className="form-control" type="text" name={name} value={value} onChange={onChange.bind(this)} required />
        </div>
      </div>
    )
  }*/

  /*useEffect(() => {
    let isMounted = true;
    const controller = new AbortController()
    let fileReader, isCancel = false;
    if(profile.picturefile) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          // setFileDataURL(result)
          //setProfile({ ...profile, picture: result, })
          //setImagePreview(true)
        }
      }
      fileReader.readAsDataURL(profile.picturefile)
    }

    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [profile])*/

  /*async function getCities() {
     const result = await axiosPrivate
       .post('getCities', {
         CountryID: profile.country_id,
         Email: auth.email,
       })
       .then((res) => {
         const { Status, Data: data = [], Message } = res.data

         if (Status) {
           setCities(res.data.Data)
         }
       })

      return result || []
    }
    // Get Profile details
    // use our useAuth hooks to update user information, it should be centralized to this context hook
  */

  const controller = new AbortController()
  let profile_endpoint = auth.userType === USERTYPE.patient ? 'getPatientDetails' : auth.userType === USERTYPE.provider ? 'getProviderDetails' : null
  async function getProfileDetails() {
    await axiosPrivate
      .post(
        profile_endpoint,
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data
        const details = data[0]

        if (Status) {
          setProfile(details)
          // setProfile({...profile, date_of_birth:(moment(details.date_of_birth).format('yyyy-MM-dd'))})
          setHours({
            HoursSunStart: details.hours_sun_start,
            HoursSunEnd: details.hours_sun_end,
            HoursMonStart: details.hours_mon_start,
            HoursMonEnd: details.hours_mon_end,
            HoursTueStart: details.hours_tue_start,
            HoursTueEnd: details.hours_tue_end,
            HoursWedStart: details.hours_wed_start,
            HoursWedEnd: details.hours_wed_end,
            HoursThuStart: details.hours_thu_start,
            HoursThuEnd: details.hours_thu_end,
            HoursFriStart: details.hours_fri_start,
            HoursFriEnd: details.hours_fri_end,
            HoursSatStart: details.hours_sat_start,
            HoursSatEnd: details.hours_sat_end,
          })
          setOldHours({
            HoursSunStart: details.hours_sun_start,
            HoursSunEnd: details.hours_sun_end,
            HoursMonStart: details.hours_mon_start,
            HoursMonEnd: details.hours_mon_end,
            HoursTueStart: details.hours_tue_start,
            HoursTueEnd: details.hours_tue_end,
            HoursWedStart: details.hours_wed_start,
            HoursWedEnd: details.hours_wed_end,
            HoursThuStart: details.hours_thu_start,
            HoursThuEnd: details.hours_thu_end,
            HoursFriStart: details.hours_fri_start,
            HoursFriEnd: details.hours_fri_end,
            HoursSatStart: details.hours_sat_start,
            HoursSatEnd: details.hours_sat_end,
          })

          setOldProfile(details)
          setAuth((prev) => ({ ...prev, ...details }))
          setTimeZone(details?.local_time_zone)

          /*If the Language is not empty then take the value and set change the language for i18next*/
          if (details['language']) {
            i18n.changeLanguage(details['language'])
          }
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  async function getCountries() {
    await axiosPrivate
      .post(
        'getCountries',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setCountries(data)
          setCityActive(true)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  async function getProvince() {
    await axiosPrivate
      .post(
        'getProvince',
        { Email: auth.email },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        const { Status, Data: data = [], Message } = res.data

        if (Status) {
          setApiProvinceList(data)
        } else {
          throw new Error(Message)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    let isMounted = true

    setAuth((prev) => ({ ...prev, profile, name: profile.first_name }))
    // if (effectRun.current){
    getProfileDetails()

    if (auth.userType === USERTYPE.patient) {
      //getCountries()
      //getProvince()
      getProvinceFromCountryId(apiProvinceList, defCountryId)
    }
    // }
    return () => {
      isMounted = false
      controller.abort()
      // effectRun.current = true;
    }
  }, [])

  useEffect(() => {
    if (!stFlgProvincesFetched && profile.country_id != '0' && apiProvinceList.length > 0) {
      //const countryProvince = getProvinceFromCountryId(apiProvinceList, profile.country_id)
      //const countryProvince = getProvinceFromCountryId(apiProvinceList, defCountryId)
      const countryProvince = arrStates;
      setCountryProvinceList(countryProvince)
      setStFlgProvincesFetched(true);
      if (countryProvince.length == 0 && profile.province == '') {
        PatientProfileSetValue('Province', '')
        setProfile((prev) => ({ ...prev, province: '', }))
      }
    }
  }, [profile.country_id, apiProvinceList, stFlgProvincesFetched])

  /*useEffect(() => {
    if (!profile?.country_id || profile?.country_id === 'undefined'|| profile?.country_id === '') return
     getCities()
  }, [profile.country_id])*/

  /*useEffect(() => {
    if(countries) {
      //console.log(countries)
    }
  }, [countries])*/

  //const frmProfileIdDocJsx = auth.userType === USERTYPE.patient && 
  let pgMsg = null;
  if(stPgMsg) {
    pgMsg = (
      <Flasher resetCallback={fResetProcessing}>
        <PageMessage text={stPgMsg} type={stPgHasErr ? 'err' : 'info'} />
      </Flasher>
    );
  }
  //---------------------------------------------------------

  return (
  <>
    <form>
      <PageWrapper>
        <ContainerFluid>
          <TableTitle title="My Profile" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      {/* <div class="uploadPicContainer">
                        <input type="file" id="input-file-now-custom-1" class="dropify" data-default-file="../assets/images/users/user-4.jpg"/>
                    </div>    */}
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <input
                          hidden
                          type="file"
                          id="input-file-now-custom-1"
                          accept="image/*"
                          // capture="user"
                          // capture="filesystem"
                          name="Image"
                          ref={imgRef}
                          onChange={handleImageInputChange}
                        />

                        {/* {auth.userType} */}
                        <img id='imProfilePic' alt=""
                          style={{ objectFit: 'cover', width: 100, height: 100 }}
                          onClick={() => {
                            Swal.fire({
                              title: comp_profile('profile_picture'),
                              html: `<img style="width: 100%" height="300px" src="${profile.picture}"></img>`,
                            })
                          }}
                          src={profile.picture}
                          className="m-4 rounded-circle profile-pic"
                        />

                        {disableForm ? null : (
                          <button type="button" className="btn btn-success btn-round waves-effect waves-light mt-2 m-2" onClick={triggerFileInput}>
                            {comp_profile('change')}
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="col">
                      {auth.userType === USERTYPE.provider ? (
                        <div className="form-group row">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                            {comp_profile('full_name')} <small className="text-danger font-13"> *</small>
                          </label>
                          <div className="col-sm-10">
                            <input
                              disabled={disableForm}
                              className={`form-control ${Boolean(ProviderProfileError.provider_name) ? 'is-invalid' : ''}`}
                              type="text"
                              name="provider_name"
                              value={profile.provider_name}
                              onChange={(e) => {
                                ProviderProfileSetValue('provider_name', e.target.value)
                                ProviderProfileTrigger('provider_name')
                                handleInputChange(e)
                              }}
                            />
                            {ProviderProfileError.provider_name && <label className="text-danger">{error_message(ProviderProfileError.provider_name.message)}</label>}
                          </div>
                        </div>
                      ) : auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('first_name')}
                            </label>
                            <div className="col-sm-10">
                              <input type="text" readOnly disabled//={disableForm}
                                name="first_name"
                                //defaultValue={profile.first_name}
                                value={profile.first_name}
                                className={`form-control ${Boolean(PatientProfileError.FirstName) ? 'is-invalid' : ''}`}
                                /*onChange={(e) => {
                                  PatientProfileSetValue('FirstName', e.target.value)
                                  PatientProfileTrigger('FirstName')
                                  handleInputChange(e)
                                }}*/
                              />
                              {PatientProfileError.FirstName && <label className="text-danger">{error_message(PatientProfileError.FirstName.message)}</label>}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('middle_name')}
                            </label>
                            <div className="col-sm-10">
                              <input type="text" readOnly disabled//={disableForm}
                                name="middle_name"
                                value={profile.middle_name}
                                //defaultValue={profile.middle_name}
                                className={`form-control ${Boolean(PatientProfileError.MiddleName) ? 'is-invalid' : ''}`}
                                /*onChange={(e) => {
                                  PatientProfileSetValue('MiddleName', e.target.value)
                                  PatientProfileTrigger('MiddleName')
                                  handleInputChange(e)
                                }}*/
                              />
                              {PatientProfileError.MiddleName && <label className="text-danger">{error_message(PatientProfileError.MiddleName.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('last_name')}
                            </label>
                            <div className="col-sm-10">
                              <input type="text" readOnly disabled//={disableForm}
                                name="last_name"
                                value={profile.last_name}
                                //defaultValue={profile.last_name}
                                className={`form-control ${Boolean(PatientProfileError.LastName) ? 'is-invalid' : ''}`}
                                /*onChange={(e) => {
                                  PatientProfileSetValue('LastName', e.target.value)
                                  PatientProfileTrigger('LastName')
                                  handleInputChange(e)
                                }}*/
                              />
                              {PatientProfileError.LastName && <label className="text-danger">{error_message(PatientProfileError.LastName.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="form-group row">
                        <label htmlFor="email-input" className="col-sm-2 col-form-label">
                          {comp_profile('email')}
                        </label>
                        <div className="col-sm-10">
                          <input
                            disabled={true}
                            readOnly
                            className="form-control"
                            type="email"
                            name="email"
                            value={auth.email}
                            //defaultValue={auth.email}
                            // onChange={handleInputChange.bind(this)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label
                          htmlFor="example-tel-input"
                          className="col-sm-2 col-form-label text-right"
                        >
                          Facebook
                        </label>
                        <div className="col-sm-10">
                          <input
                            disabled={disableForm}
                            className="form-control"
                            type="tel"
                            name="facebook"
                            value={profile.facebook}
                            onChange={handleInputChange.bind(this)}
                          />
                        </div>
                      </div> */}
                      {auth.userType === USERTYPE.provider ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                              {comp_profile('about_me')}
                            </label>
                            <div className="col-sm-10">
                              <textarea
                                disabled={disableForm}
                                className="form-control"
                                type="tel"
                                rows="4"
                                name="provider_description"
                                value={profile.provider_description}
                                onChange={(e) => {
                                  ProviderProfileSetValue('provider_description', e.target.value)
                                  //ProviderProfileTrigger('provider_description')
                                  handleInputChange(e)
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                              {comp_profile('specialization')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm}
                                className="form-control"
                                type="tel"
                                name="practice"
                                value={profile.practice}
                                onChange={(e) => {
                                  ProviderProfileSetValue('practice', e.target.value)
                                  //ProviderProfileTrigger('practice')
                                  handleInputChange(e)
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="form-group row">
                        <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                          {comp_profile('contact_info')}
                        </label>
                        <div className="col-sm-10">
                          <PhoneInput //PhoneInput
                            disabled={disableForm}
                            name="contact_info"
                            placeholder="(xxx) xxx-xxxx"
                            required
                            value={profile.contact_info || ''}
                            country={defCountryCode}
                            //defaultCountry={defCountryCode}
                            //addInternationalOption={false}
                            international={false}
                            //withCountryCallingCode={false}
                            //countries={[defCountryCode]}
                            className={`form-control ${Boolean(PatientProfileError.ContactInfo) ? 'is-invalid' : ''}`}
                            onChange={(value) => {
                              setProfile((prev) => ({ ...prev, contact_info: value }))
                              PatientProfileSetValue('ContactInfo', value)
                              //PatientProfileTrigger('ContactInfo')
                              ProviderProfileSetValue('ContactInfo', value)
                              //ProviderProfileTrigger('ContactInfo')
                            }}
                          />

                          {PatientProfileError.ContactInfo && <label className="text-danger">{error_message(PatientProfileError.ContactInfo.message)}</label>}
                        </div>
                      </div>

                      {auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('address_line_1')}
                            </label>
                            <div className="col-sm-10">
                              <input ref={addrRef}
                                disabled={disableForm} type="text"
                                name="address_line_1" id="elAddr1"
                                //value={profile.address_line_1}
                                defaultValue={profile.address_line_1}
                                className={`form-control ${Boolean(PatientProfileError.Address1) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Address1', e.target.value)
                                  PatientProfileTrigger('Address1')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.Address1 && <label className="text-danger">{error_message(PatientProfileError.Address1.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('address_line_2')}
                            </label>
                            <div className="col-sm-10">
                              <input
                                disabled={disableForm} type="text"
                                name="address_line_2" id="elAddr2"
                                value={profile.address_line_2}
                                className={`form-control ${Boolean(PatientProfileError.Address2) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Address2', e.target.value)
                                  PatientProfileTrigger('Address2')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.Address2 && <label className="text-danger">{error_message(PatientProfileError.Address2.message)}</label>}
                            </div>
                          </div>
                          {/*profileAddrField*/}
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">{comp_profile('city')}</label>
                            <div className="col-sm-4">
                              <input
                                disabled={disableForm} type="text"
                                name="city" id="elCity"
                                value={profile.city}
                                className={`form-control ${Boolean(PatientProfileError.City) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('City', e.target.value)
                                  PatientProfileTrigger('City')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.City && <label className="text-danger">{error_message(PatientProfileError.City.message)}</label>}
                            </div>
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('state_province')}
                            </label>
                            <div className="col-sm-4">
                              {//countryProvinceList.length > 0 ? (
                                arrStates.length > 0 ? (
                                <select
                                  disabled={disableForm}
                                  name="province" id="selProvince"
                                  value={profile.province}
                                  className={`form-control ${Boolean(PatientProfileError.Province) ? 'is-invalid' : ''}`}
                                  onChange={(e) => {
                                    PatientProfileSetValue('Province', e.target.value)
                                    PatientProfileTrigger('Province')
                                    handleInputChange(e)
                                  }}
                                >
                                  <option value={''}>Select</option>
                                  {arrStates.map((province, index) => (
                                    <option value={province.province} key={index}>
                                      {province.province}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  disabled={disableForm} type="text"
                                  name="province" id="selProvince"
                                  value={profile.province}
                                  className={`form-control ${Boolean(PatientProfileError.Province) ? 'is-invalid' : ''}`}
                                  onChange={(e) => {
                                    PatientProfileSetValue('Province', e.target.value)
                                    PatientProfileTrigger('Province')
                                    handleInputChange(e)
                                  }}
                                />
                              )}
                              {PatientProfileError.Province && <label className="text-danger">{error_message(PatientProfileError.Province.message)}</label>}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('zip_code')}
                            </label>
                            <div className="col-sm-4">
                              <input
                                disabled={disableForm} type="text"
                                name="zip_code" id="elZip"
                                value={profile.zip_code}
                                className={`form-control ${Boolean(PatientProfileError.ZIPCode) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('ZIPCode', e.target.value)
                                  PatientProfileTrigger('ZIPCode')
                                  handleInputChange(e)
                                }}
                              />
                              {PatientProfileError.ZIPCode && <label className="text-danger">{error_message(PatientProfileError.ZIPCode.message)}</label>}
                            </div>
                            <label className="col-sm-2 col-form-label ">{comp_profile('country')}</label>
                            <div className="col-sm-4">
                            <input
                                disabled//={disableForm}
                                readOnly type="text"
                                name="country_idtx" id="country_idtx"
                                //value={profile.country_id || defCountryId}
                                value={defCountryName}
                                //defaultValue={defCountryName}
                                className={`form-control ${Boolean(PatientProfileError.CountryID) ? 'is-invalid' : ''}`}
                                /*onChange={(e) => {
                                  PatientProfileSetValue('CountryID', e.target.value)
                                  PatientProfileTrigger('CountryID')
                                  handleInputChange(e)
                                }}*/
                              />
                              <input type='hidden' name="country_id" id='country_id'
                                value={defCountryId} />
                              {/*{(() => {
                                  //PatientProfileSetValue('CountryID', defCountryId)
                                })()
                              }*/}
                              {/*<select
                                disabled={disableForm}
                                name="country_id"
                                value={profile.country_id}
                                className={`form-control ${Boolean(PatientProfileError.CountryID) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('CountryID', e.target.value)
                                  PatientProfileTrigger('CountryID')
                                  handleInputChange(e)
                                }}
                              >
                                <option value={''}>Select</option>
                                {countries.map((country, index) => (
                                  <option value={country.country_id} key={index}>
                                    {country.description}
                                  </option>
                                ))}
                              </select>*/}
                              {PatientProfileError.CountryID && <label className="text-danger">{error_message(PatientProfileError.CountryID.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {auth.userType === USERTYPE.patient ? (
                        <>
                          <div className="form-group row">
                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('local_timezone')}
                            </label>
                            <div className="col-sm-4">
                              {flgUseTimezoneScheme2? (
                                <TimeZoneSelect2
                                ddId='selTZ' required={true}
                                disabled={disableForm}
                                tzOptions={arrTz2}
                                value={profile.local_time_zone}
                                filterCountryCode={defCountryCode}
                                elStyle = {{marginLeft: '10px', marginRight: '10px', maxWidth: 400}}
                                className={`form-control ${Boolean(PatientProfileError.LocalTimeZone) ? 'is-invalid' : ''}`}
                                setTimeZone={(selected) => {console.log(selected);
                                  setProfile((prev) => ({
                                    ...prev,
                                    local_time_zone: selected || '',
                                  }))
                                  PatientProfileSetValue('LocalTimeZone', selected)
                                  PatientProfileTrigger('LocalTimeZone')
                                }}

                                /*onChange={(e) => {
                                  PatientProfileSetValue('Province', e.target.value)
                                  PatientProfileTrigger('Province')
                                  handleInputChange(e)
                                }}*/
                                />
                              ) : (
                              <TimeZoneSelect
                                ddId='selTZ' required={true}
                                disabled={disableForm}
                                value={profile.local_time_zone}
                                filterCountryCode={defCountryCode}
                                elStyle = {{marginLeft: '10px', marginRight: '10px', maxWidth: 400}}
                                className={`form-control ${Boolean(PatientProfileError.LocalTimeZone) ? 'is-invalid' : ''}`}
                                setTimeZone={(selected) => {console.log(selected);
                                  setProfile((prev) => ({
                                    ...prev,
                                    local_time_zone: selected || '',
                                  }))
                                  PatientProfileSetValue('LocalTimeZone', selected)
                                  PatientProfileTrigger('LocalTimeZone')
                                }}

                                /*onChange={(e) => {
                                  PatientProfileSetValue('Province', e.target.value)
                                  PatientProfileTrigger('Province')
                                  handleInputChange(e)
                                }}*/
                              />)}
                              {PatientProfileError.LocalTimeZone && <label className="text-danger">{error_message(PatientProfileError.LocalTimeZone.message)}</label>}
                            </div>

                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                              {comp_profile('date_of_birth')}
                            </label>
                            <div className="col-sm-4">
                              <input ref={dobRef}
                                readOnly disabled={disableForm}
                                //onkeydown="false"
                                //type="date" lang="us-EN"
                                required={true}
                                placeholder={'mm-dd-yyyy'}
                                id={elDatePickerId}
                                name="date_of_birth"
                                value={profile?.date_of_birth && profile.date_of_birth !== dummyDOB ? dateFormat(profile?.date_of_birth) : ''}
                                //defaultValue={dateFormat(profile?.date_of_birth) || ''}
                                className={`form-control ${Boolean(PatientProfileError.DateOfBirth) ? 'is-invalid' : ''}`}
                                style={{backgroundColor: '#fff'}}
                                //onChangeCapture={(e) => {
                                /*onSelect={(e) => {
                                  const inDate = e.target.value;
                                  //e.target.value = '';
                                  const outDate = moment(inDate).format('YYYY-MM-DD');
                                  console.log('Dttt', {inDate, outDate}, e.target);
                                  PatientProfileSetValue('DateOfBirth', outDate)
                                  PatientProfileTrigger('DateOfBirth')
                                  handleInputChange(e)
                                }}*/
                              />
                              {
                                (()=>{//$("#datepicker").val(date);
                                    if(profile?.date_of_birth && profile.date_of_birth !== dummyDOB) {
                                      const objDobDt =  new Date(profile?.date_of_birth);
                                      setTimeout(()=> {
                                        $('#'+elDatePickerId).datepicker('setDate', new Date(objDobDt));
                                      }, 20);
                                    }
                                })()
                              }
                              {/*<DateSelector elId={'date_of_birth2'} />*/}
                              {PatientProfileError.DateOfBirth && <label className="text-danger">{error_message(PatientProfileError.DateOfBirth.message)}</label>}
                            </div>
                          </div>

                          {/* <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('language')}
                            </label>
                            <div className="col-sm-4">
                              <select
                                disabled={disableForm}
                                name="language"
                                value={profile.language}
                                className={`form-control ${Boolean(PatientProfileError.Language) ? 'is-invalid' : ''}`}
                                onChange={(e) => {
                                  PatientProfileSetValue('Language', e.target.value)
                                  PatientProfileTrigger('Language')
                                  handleInputChange(e)
                                }}
                              >
                                {languageOptions.map((option) => (
                                  <option value={option.value} key={option.value}>
                                    <img src={option.flagIcon} alt={option.label} className="flag-icon" /> {option.label}
                                  </option>
                                ))}
                              </select>
                              {PatientProfileError.Language && <label className="text-danger">{error_message(PatientProfileError.Language.message)}</label>}
                            </div>
                          </div> */}
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                              {comp_profile('language')}
                            </label>
                            <div className="col-sm-4">
                              <LanguageDropdown
                                isDisabled={disableForm}
                                value={profile.language}
                                onChange={handleLanguageChange}
                                error={Boolean(PatientProfileError.Language)} // Replace with your error logic
                              />
                              {PatientProfileError.Language && <label className="text-danger">{error_message(PatientProfileError.Language.message)}</label>}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12">
                          <div className="form-group row">
                            {/* <div className='row'> */}
                            <div className="col-md-3">
                              <h5>{comp_profile('sunday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Sun" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('monday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Mon" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('tuesday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Tue" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('wednesday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Wed" disabled={disableForm} oldHours={oldHours} />
                            </div>

                            {/* <div className='row'> */}

                            <div className="col-md-3">
                              <h5>{comp_profile('thursday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Thu" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('friday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Fri" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div className="col-md-3">
                              <h5>{comp_profile('saturday')}</h5>
                              <ScheduleSelect hours={hours} setHours={setHours} weekday="Sat" disabled={disableForm} oldHours={oldHours} />
                            </div>
                            <div>{/* </div> */}</div>
                            {/* </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="padS dFlexC xtBdr2">
                    <div>&nbsp;</div>
                    <div className="xtBdr2">
                      <div className="xdFlexC xtBdr2">
                        {pgMsg}
                      </div>
                    </div>
                    <div className="txAC xtBdr2">
                      {flgEditable || !disableForm ? (
                        //stProcessing ? (<RingLoading size={40} />) :
                        (
                          <button
                            style={{ marginRight: '10px' }}
                            onClick={handleSubmit}
                            type="button"
                            className={"btn btn-success btn-round waves-effect waves-light"}
                            disabled={stProcessing || (profile === oldProfile && hours === oldHours)}
                          >
                            {comp_profile('submit_button')}
                          </button>
                        )
                      ): null}
                      {/*false ? (//disableForm ? (
                        <button style={{ marginRight: '10px' }} onClick={() => setDisableForm((prev) => !prev)} type="button" className="btn btn-success btn-round waves-effect waves-light">
                          {comp_profile('edit_profile_button')}
                        </button>
                      ) : null}
                      {false ? (//!disableForm ? (
                        <button type="button" className="btn btn-danger btn-round waves-effect waves-light" onClick={handleCancelEdit}>
                          {comp_profile('cancel_button')}
                        </button>
                      ) : null*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </PageWrapper>
    </form>
    {auth.userType === USERTYPE.patient && (
      <div id="ctnProfIdDoc" style={{marginTop: '-8.5rem'}}>
        <ProfileIdDocument
          compData={{ auth, profile, disableForm, }}
          compMethods={{getProfileDetails, setAuth, setProfile, setOldProfile}}
        />
        {/*frmProfileIdDocJsx*/}
      </div>
    )}
    <div id='ctnProffInsDoc' style={{marginTop: '-8.5rem'}}>
      <Insurance flgAsComponent={true}/>
    </div>
  </>
  )
};
