import { configureStore } from "@reduxjs/toolkit";
//import { setupListeners } from "@reduxjs/toolkit/dist/query";
//import { persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
//import patientReducer from './slice/patient'
//import patientReducer from 'redux/slice/patient';
import patientReducer from 'v2/src/lib/services/patient/patientSlice';
//import { userApi } from './api/User'

import nHAppReducer, { nHApi, //slNameReducer
} from 'v2/src/lib/services/public/nHApi';
import { authApi } from 'v2/src/lib/services/auth/authApi';
import authReducer from 'v2/src/lib/services/auth/authSlice';
import { nHDocumentApi } from 'v2/src/lib/services/nHDocumentApi';
import { locationApi } from 'v2/src/lib/services/locationApi';
import { profileApi } from 'v2/src/lib/services/profileApi';
import { providerApi } from 'v2/src/lib/services/provider/providerApi';
//import { userApi } from 'v2/src/lib/services/auth/authApi';
//---------------------------------------------------------

const appStore = configureStore({
  reducer: {
    [nHApi.reducerPath]: nHApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [nHDocumentApi.reducerPath]: nHDocumentApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    auth: authReducer,
    nHDocumentApi: nHDocumentApi,
    locationApi: locationApi,
    profileApi: profileApi,
    providerApi: providerApi,
    patient: patientReducer,
  },
  //devTools: process.env.NODE_ENV !== 'production',
  middleware: (gDM) => gDM({ serializableCheck: false }).concat([
      nHApi.middleware,
      authApi.middleware,
      nHDocumentApi.middleware,
      locationApi.middleware,
      profileApi.middleware,
      providerApi.middleware,
      //userApi.middleware,
  ]),
});

//setupListeners(appStore.dispatch);
//export const persistor = persistStore(appStore);
export default appStore;
