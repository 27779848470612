import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from 'react-phone-number-input';
//import PhoneInput from "react-phone-number-input";
//import {default as PhInput} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { usePlacesWidget } from "react-google-autocomplete";
import moment from "moment";
//import i18n from 'i18n';
import { DEF_COUNTRY_ID, DEF_COUNTRY_CODE, DEF_COUNTRY_NAME,
  DEF_LANGUAGE_CODE, DEF_PROFILE_PIC_URL, GOOGLE_PLACES_API_KEY,
  USER_TYPES, FLG_USE_SWAL,// NS_API_BASE_URL,
} from 'v2/src/config/appConfig';
import { fGetAUserInf, fGetLocalSt,
  fParseGglPlaces, fFormatGglAddressComps,
} from 'v2/src/lib/appUtils';
import { fStableSort, fGetComparator } from 'v2/src/lib/objUtils';//fIsEmpty
//import {fDownloadImg} from 'v2/src/lib/netUtils';
import { fCallNHApi, fGetUserProfileUpData, } from './appProfileFx';
import { PageMessage, Flasher, LanguageDropdown, LottieSpinner } from 'v2/src/core';
import ProfilePicSetter from 'v2/src/core/UploadImage/ProfilePicSetter';
import TimeZoneSelect2 from 'v2/src/core/TimezoneDrodown/Timezone2';
//import GPlacesAddressField from 'v2/src/core/gPlacesAddressField';
import ProfileFormSchema from 'v2/src/schema/profileFormSchema';
import { rSetProfilePic, rSetProfileData } from 'v2/src/lib/services/auth/authSlice';
import { useSetNSUserMutation } from 'v2/src/lib/services/nSUtilApi';
import { useLocationCompQuery,
  //useProfileAddressListQuery, useAddressDetailsQuery,
  useAddProfileAddressMutation, useSetAddressMutation
} from 'v2/src/lib/services/locationApi';
import { useUserProfileDetailQuery, useLazyUserDetailsQuery,//useLazyUserProfileDetailQuery,
  useSetUserMutation, useSetUserProfileMutation,
  //useUserProfileListQuery, useUserProfileQuery,
} from 'v2/src/lib/services/profileApi';
//---------------------------------------------------------

export default function ProfileForm() {
  const fDispatch = useDispatch();
  let aUData = fGetAUserInf();//console.log('aUDataPF', aUData);
  const dobRef = useRef();

  const { t: error_message } = useTranslation('error_message');
  const { t: comp_profile } = useTranslation('comp_profile');
  const [stPgMsg, setStPgMsg] = useState('');
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);
  const [stDisableForm, setStDisableForm] = useState(false);

  const flgEditable = !stDisableForm;
  const flgUseTimezoneScheme2 = true;
  //const languageCookieName = 'niuHLnId';
  const countryCookieName = 'niuHCId';
  //const selLnId = fGetLocalSt(languageCookieName) || DEF_LANGUAGE_ID;
  const selCountryId = fGetLocalSt(countryCookieName) || DEF_COUNTRY_ID;
  const defCountryId = DEF_COUNTRY_ID;
  const defCountryCode = DEF_COUNTRY_CODE === 'USA' ? 'US' : DEF_COUNTRY_CODE;
  const defCountryName = DEF_COUNTRY_NAME;
  const aUserId = aUData?.userIdNH;
  const aUserType = aUData?.userType;//||
  const aUserNSId = aUData?.userIdNS;
  const aUserProfileId = aUData?.details?.profileId;
  const aUserLanguageCode = DEF_LANGUAGE_CODE;
  const dummyDob = '1920-01-01';
  const elDatePickerId = 'DateOfBirth';//'date_of_birth';
  let aUserDob, aUserAddressData, aUserAddressId;
  //---------------------------------------------------------

  const [fSetUserData, /*{ reset: fResetU }*/] = useSetUserMutation();
  const [fSetUserProfile] = useSetUserProfileMutation();
  const [fAddUserAddr] = useAddProfileAddressMutation();
  const [fSetUserAddr] = useSetAddressMutation();
  const [fSetNSUser] = useSetNSUserMutation();
  const [fGetUserProfDetails] = useLazyUserDetailsQuery({ profileId: null, userType: null });

  let arrLangs = [], arrStates = [], arrTz = [];//arrCountries
  const { data: locApiData, //refetch: fRefetchLoc,
    isLoading: locWait, isFetching: locWait2,
    //isError: locErr,//isSuccess: ,
  } = useLocationCompQuery(//{refetchOnMountOrArgChange: true}
    {countryId: selCountryId}, {skip: selCountryId===null}
  );//const objLocData = locApiData;//?.data
  //locApiData && console.log('locApiData', locApiData);
  if(locApiData) {
    //arrCountries = locApiData?.countries?.data || [];
    arrLangs = locApiData?.languages?.data || [];
    arrStates = locApiData?.states?.data || [];
    arrStates = fStableSort(arrStates, fGetComparator('name', 'asc'))
    arrTz = locApiData?.timezones?.data || [];
  }

  const arrComps = ['user', 'role', 'language', 'addresses','patient',];
  const { data: profileApiData, refetch: fRefetchProfile,
    isLoading: profWait, isFetching: profWait2,
    isError: profErr,//isSuccess: profOK,
  } = useUserProfileDetailQuery( {profileId: aUserProfileId, arrComps},
    {skip: aUserProfileId===null, refetchOnMountOrArgChange: true}
  );
  const objProfData = profileApiData?.data;
  aUserDob = objProfData?.date_of_birth;
  //aUserId = objProfData?.id;
  if(objProfData?.addresses?.length) {
    aUserAddressData = objProfData?.addresses.find((rAddr) => rAddr?.is_primary === 1);
    //aUserAddressData = objProfData?.addresses.find((rAddr) => rAddr?.is_primary == 1);
    //aUserAddressData = objProfData?.addresses.find((rAddr) => rAddr?.is_primary == true);
    aUserAddressId = aUserAddressData?.id;
  }
  objProfData && console.log('objProfData_'+profErr,//+'_'+aUserAddressId,
    {'aUDataPF': aUData, objProfData, aUserAddressData});
  useEffect(()=> {
    if(profErr) {
      setStPgMsg('Error fetching user profile data');
      //setStPgMsg([<p>Error fetching user profile data</p>, <p>Please try again later..</p>]);
      setStPgHasErr(true);
    }
  }, [profErr]);

  /*const pAddrsListParams = { inParams: {
      profile_id: aUserProfileId, addressable_type: 'profile', addressable_id: aUserProfileId
  }};
  const { data: profileAddrsApiData, refetch: fRefetchProfileAddrs,
    isLoading: profAddrsWait, isFetching: profAddrsWait2,
    isError: profAddrsErr,//isSuccess: profAddrsOK,
  } = useProfileAddressListQuery(pAddrsListParams,
    {skip: aUserProfileId===null, refetchOnMountOrArgChange: true}
  );
  const objProfAddrsData = profileAddrsApiData?.data;
  if(objProfAddrsData) {
    aUserAddressData = objProfAddrsData.find((rAddr) => rAddr?.is_primary === 1);
    //aUserAddressData = objProfAddrsData.find((rAddr) => rAddr?.is_primary == 1);
    //aUserAddressData = objProfAddrsData.find((rAddr) => rAddr?.is_primary == true);
    aUserAddressId = aUserAddressData?.id;
  }
  objProfAddrsData && console.log('objProfAddrsData_'+aUserAddressId,
    {objProfAddrsData, aUserAddressData});*/
  
  const isPageLoading = (
    locWait || locWait2 || profWait || profWait2 //||
    //profAddrsWait || profAddrsWait2
  );
  const fResetProcessing = (flgClearPgMsg=false) => {
    setStProcessing(false);
    //if(flgClearPgMsg) {
      setStPgMsg(''); setStPgHasErr(false);
    //}
  };
  //---------------------------------------------------------

  const {
    register: fRegFrmProfile, reset: fResetFrmProfile,
    trigger: fTriggerFrmProfile, //control: frmProfileControl,
    setValue: fSetFrmProfileVal, getValues: fGetFrmProfileVal,
    setError, // Add this to set errors on the form
    clearErrors, // Add this to clear errors when the input is valid
    formState: { errors: errFrmProfile, isValid: isValidFrmProfile },
  } = useForm({
    resolver: yupResolver(ProfileFormSchema),
    //shouldUseNativeValidation: true
  });
  useEffect(() => {
    const obPData = profileApiData?.data;
    if (obPData) {
      fSetFrmProfileVal('FirstName', obPData?.user?.first_name || '');
      fSetFrmProfileVal('MiddleName', obPData?.user?.middle_name || '');
      fSetFrmProfileVal('LastName', obPData?.user?.last_name || '');
      fSetFrmProfileVal('contactNum', obPData?.user?.phone || '');
      fSetFrmProfileVal('Address1', aUserAddressData?.address_line_1 || '');
      fSetFrmProfileVal('Address2', aUserAddressData?.address_line_2 || '');
      fSetFrmProfileVal('City', aUserAddressData?.city || '');
      fSetFrmProfileVal('Province', aUserAddressData?.state?.id || '');
      fSetFrmProfileVal('ZIPCode', aUserAddressData?.zipcode || '');
      fSetFrmProfileVal('LocalTimeZone', obPData?.timezone_id || 0);
      fSetFrmProfileVal('CountryID', defCountryId);
      fSetFrmProfileVal('DateOfBirth', obPData?.date_of_birth !== dummyDob ? obPData?.date_of_birth : '');
    }else{
      fRefetchProfile();
    }
  }, [profileApiData, locApiData, fSetFrmProfileVal]); // Dependency on profileApiData ensures fresh fetch  

  useEffect(() => {
    //jqueryFn();
    $('#'+elDatePickerId).datepicker({
      changeMonth: true,  changeYear: true, 
      placeholder: 'mm-dd-yyyy', //dateFormat: "M-dd-yy",
      yearRange: "-90:+00", //yearRange: "-90:-10",
      //defaultDate: '',//valueDefault: null,
      maxDate: -1, //maxDate: new Date(),//selected, beforeShow 
      //showOn: 'both',//"button",
      //buttonImage: "images/calendar.gif",
      //buttonImageOnly: true, buttonText: ''
      onSelect: (dtVal, inst) => {
        const inDate = dtVal;
        const outDate = moment(inDate).format('YYYY-MM-DD');
        const objDob = document.getElementById(elDatePickerId);
        fSetFrmProfileVal('DateOfBirth', outDate);
        fTriggerFrmProfile('DateOfBirth');
        //fSetInputChange(elDatePickerId, outDate);
        //objDob.value = outDate;
        //setTimeout(()=> { $(this).datepicker('setDate', new Date(outDate)); }, 20);
        setTimeout(()=> {
          //objDob.value = outDate;
          //objDob.value = fFormateDate(outDate);
          $(this).datepicker('setDate', new Date(outDate));
        }, 20);
      }
    }).on('dp.change', () => { $(this).trigger('blur'); });

    /*if(0) {console.log('dob', aUserDob);
      const objDobDt =  new Date(aUserDob);
      setTimeout(()=> {
        $('#'+elDatePickerId).datepicker('setDate', new Date(objDobDt));
      }, 100);
    }*/
    //return () => { $('#'+elDatePickerId).off('datepicker'); };
  }, [dobRef, profileApiData]);

  const fAutoPopulateAddress = async (tEl, plInf, langCode=null) => {
    let addrComps, tzInf, cTZs; console.log('Pl', plInf);

    addrComps = fParseGglPlaces(plInf, GOOGLE_PLACES_API_KEY, langCode);
    console.log('addrComps', addrComps);
    //console.log('arrStatessss', arrStates);
    if(addrComps?.selAddr) {
      let nAddrComps = fFormatGglAddressComps(addrComps);
      console.log('nAddrComps', nAddrComps);
      //const arrStOpts = [];
      if(nAddrComps.nProvince?.length) {//tEl
        //elAddr1, elAddr2, elCity, selProvince, elZip, country_idtx, selTZ
        const elAddr1 = document.getElementById('elAddr1');
        const elAddr2 = document.getElementById('elAddr2');
        const elCity = document.getElementById('elCity');
        const elProvince = document.getElementById('selProvince');
        //const elCountry = document.getElementById('country_idtx');
        const elZip = document.getElementById('elZip');
        const elTz = document.getElementById('selTz');
        //const arrStOpts = [...elProvince?.options].map(s=>s?.text.toLowerCase());
        const arrStOpts = [...elProvince?.options].map(
          //s=>[s?.value, s?.getAttribute('data-scode')]
          s=>[s?.value, s?.getAttribute('data-scode'), s?.getAttribute('data-ptzid')]
        );//console.log('arrStOpts', arrStOpts);
        let provData;

        if(elAddr1 && nAddrComps.nAddr1?.length) {
          elAddr1.value = nAddrComps.nAddr1;
          fSetFrmProfileVal('Address1', nAddrComps.nAddr1);
          //fTriggerFrmProfile('Address1');
          //fSetInputChange('address_line_1', nAddrComps.nAddr1);
        }
        if(elAddr2) {// && nAddrComps.nAddr2?.length) {
          elAddr2.value = nAddrComps.nAddr2;
          fSetFrmProfileVal('Address2', nAddrComps.nAddr2);
          //fTriggerFrmProfile('Address2');
          //fSetInputChange('address_line_2', nAddrComps.nAddr2);
        }
        if(elCity) {// && nAddrComps.nCity?.length) {
          elCity.value = nAddrComps.nCity;
          fSetFrmProfileVal('City', nAddrComps.nCity);
          //fTriggerFrmProfile('City');
          //fSetInputChange('city', nAddrComps.nCity);
        }
        if(elProvince) {// && nAddrComps.nProvince?.length) {
          //provData = arrStates.filter(sts => sts?.code === nAddrComps?.nProvinceCode);
          provData = arrStOpts.filter(
            sts => sts[1] === nAddrComps?.nProvinceCode?.toLowerCase()
          );
          provData = provData?.at(0);console.log('provData', provData);
          elProvince.value = provData?.at(0);
          fSetFrmProfileVal('Province', provData?.at(0));
          //elProvince.value = nAddrComps.nProvince;
          //elProvince.selectedIndex = province?.long_name;
          //fSetFrmProfileVal('Province', nAddrComps.nProvince);
          //fTriggerFrmProfile('Province');
          //fSetInputChange('province', nAddrComps.nProvince);

          /*let stTz, selProvinceInf =
            arrStates.filter(st => st.province === nAddrComps.nProvince);
          console.log('selProvinceInf '+nAddrComps.nProvince, {selProvinceInf, arrStates});
          if(elTz && selProvinceInf?.length) {
            selProvinceInf = selProvinceInf[0];
            //console.log('selProvinceInf', selProvinceInf);
            stTz = selProvinceInf?.primaryTz;
          }
          if(stTz && arrTz2?.length) {
            let stTzInf = arrTz2.filter(tz => tz.tzCode === stTz);
            //console.log('selProvinceInf', {stTz, stTzInf, selProvinceInf});
            if(stTzInf?.length) {
              stTz = fGetHMFormat2(stTzInf[0]['utcOffsetMinute']);
              //console.log('stTz2', stTz);
              elTz.value = stTz;
              fSetFrmProfileVal('LocalTimeZone', stTz);
              //fTriggerFrmProfile('LocalTimeZone');
              //fSetInputChange('local_time_zone', stTz);
            }
          }*/
          let stTzId = provData?.at(2);
          if(elTz && stTzId) {
            elTz.value = stTzId;
            fSetFrmProfileVal('LocalTimeZone', stTzId);
            //fTriggerFrmProfile('LocalTimeZone');
          }
        }
        if(elZip) {// && nAddrComps.nZip?.length) {
          elZip.value = nAddrComps.nZip;
          fSetFrmProfileVal('ZIPCode', nAddrComps.nZip);
          //fTriggerFrmProfile('ZIPCode');
          //fSetInputChange('zip_code', nAddrComps.nZip);
        }

        /*if(10 && addrComps?.tzApiUrl) {
          tzInf = await fCallAxApi(addrComps.tzApiUrl);
          //if(tzInf?.status === 'OK') {}
        }
        cTZs = fGetArrTimeZone(defCountryCode);
        if(cTZs?.length) {
          let addrTz = [];
          if(nAddrComps?.nCity?.length) {
            addrTz = cTZs.filter(tz => tz?.name.indexOf(nAddrComps.nCity) > -1);
          }
          if(!addrTz && nAddrComps.nProvince?.length) {
            addrTz = cTZs.filter(tz => tz?.name.indexOf(nAddrComps.nProvince) > -1);
          }
          console.log('addrTz', addrTz);
          if(addrTz.length) {
            //elTz.value = addrTz[0]?.name;
            //fSetFrmProfileVal('', nAddrComps.);
            //fTriggerFrmProfile('');
            //fSetInputChange('local_time_zone', nAddrComps.?);
          }
        }*/
      }
    }
  };
  const { ref: addrRef } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {//console.log('onPlaceSelected Called', addrRef.current);
      try {
        const inEl = addrRef.current;
        inEl && place && fAutoPopulateAddress(inEl, place, aUserLanguageCode);
      } catch {}
    },
    options: {//fields:[], // libraries: [],
      //If nothing is specified, all types are returned
      language: aUserLanguageCode,
      //types: ['(regions)'],//food|restaurant, 'establishment',
      types: ['street_number', 'street_address', 'postal_code'],// 'cities', 'utc_offset'
      inputtype: 'textquery',
      componentRestrictions: { country: defCountryCode },
    },
  });
  //---------------------------------------------------------
  
  const fCheckIsNewProfile = (profData) => {
    //return (stPIdDocsInf.savedDocCount <= 0 || !address_line_1 ||
    //  !address_line_1?.length ||! DOB
  };
  const fHandleLanguageChange = (selOpt) => {
    fSetFrmProfileVal('Language', selOpt.value);
  };
  
  const fResetProfileForm = () => { fResetFrmProfile(); };
  const fHandleProfilePicChange = async (fl) => {
    if(typeof fl === 'object') {
      setStProcessing(true);
      const formData = new FormData();
      formData.append('profile_pic', fl);//, 'fName');
      const profPicUpResp = await fCallNHApi('profPicUp', fSetUserProfile,
        {profileId: aUserProfileId, inParams: formData});
      if(profPicUpResp?.result === true) {
        const nSFormData = new FormData();
        nSFormData.append('profile', fl);
        const dumProfPicNSUpResp = await fSetNSUser(
          {userId: aUserNSId, inParams: nSFormData});
        fRefetchProfile();
        // setTimeout(async ()=> {
          const uProfResp = await fGetUserProfDetails(
            { profileId: aUserProfileId, userType: USER_TYPES.patient });
          const newProfilePicUrl = uProfResp?.data?.data?.profile_pic_url;
          if(newProfilePicUrl) {
            fDispatch(rSetProfilePic({profilePic: newProfilePicUrl}));
          }
        // }, 2000);
        /*setTimeout(async()=> {
          let nIUrl = await fDownloadImg(objProfData?.profile_pic_url)
          console.log('nIUrl', nIUrlnIUrl);
          if(nIUrl) {}
        }, 4000);*/
      }
      setStProcessing(false);
    }
  };
  const fHandleProfileSubmit = async() => {
    let formData, profSaveResp, hasValidInputs = false;
    const inData = fGetFrmProfileVal();
    console.log('inDataPProf', {inData, aUserType});//return;

    const checkValid = await fTriggerFrmProfile();
    hasValidInputs = checkValid && Object.keys(checkValid).length === 0;
    console.log('checkValidFP', {checkValid, hasValidInputs});//return;
    if(hasValidInputs && inData) {// && )
      profSaveResp = {
        rSaveUser: null, rSaveProfile: null, rSaveAddress: null,
      };
      setStProcessing(true);
      formData = new FormData();
      //let frmVals = Object.fromEntries(formData);
      //first_name, middle_name, last_name, phone
      formData.append('phone', inData?.contactNum);
      profSaveResp.rSaveUser = await fCallNHApi('userUp', fSetUserData,
        {userId: aUserId, inParams: formData});

      formData = new FormData();
      //profile_pic, date_of_birth, gender, language_id, timezone_id
      formData.append('date_of_birth', inData?.DateOfBirth);
      //formData.append('language_id', inData?.Language;
      formData.append('timezone_id', inData?.LocalTimeZone);
      profSaveResp.rSaveProfile = await fCallNHApi('userProfUp', fSetUserProfile,
        {profileId: aUserProfileId, inParams: formData});

      formData = new FormData();
      //profile_id, address_line_1, address_line_2, country_id,
      //state_id, city, zipcode, lat, long, is_primary
      formData.append('is_primary', 1);
      //formData.append('is_primary', true);
      formData.append('address_line_1', inData?.Address1);
      formData.append('address_line_2', inData?.Address2 || '');
      formData.append('city', inData?.City);
      formData.append('state_id', inData?.Province);
      formData.append('zipcode', inData?.ZIPCode);
      formData.append('country_id', inData?.CountryID);
      //formData.append('lat', inData?);
      //formData.append('long', inData?);
      let fFx, fFxParams={};//sFlgAddr
      if(aUserAddressId) {
        fFx = fSetUserAddr;
        fFxParams['addressId'] = aUserAddressId;
     } else {
        formData.append('addressable_type', 'profile');
        formData.append('addressable_id', aUserProfileId);
        formData.append('profile_id', aUserProfileId);
        fFx = fAddUserAddr;
      }
      fFxParams['inParams'] = formData;
      profSaveResp.rSaveAddress = await fCallNHApi('userProfUp', fFx, fFxParams);
      console.log('profSaveResp', profSaveResp);

      if(profSaveResp?.rSaveUser?.result || profSaveResp?.rSaveProfile.result ||
        profSaveResp?.rSaveAddress.result
      ) {
        fRefetchProfile();
        //const uPrUpdata = fGetUserProfileUpData(inData, aUserType);
        //fDispatch(rSetProfileData(uPrUpdata || {}));
      }
      if(profSaveResp?.rSaveUser?.result && profSaveResp?.rSaveProfile.result &&
        profSaveResp?.rSaveAddress.result
      ) {
        if(FLG_USE_SWAL) {
          Swal.fire({ icon: 'success',
            text: comp_profile('profile_update_successfull') });
        } else {
          setStPgMsg(comp_profile('profile_update_successfull'));
        }
      } else {// if(0) {
        if(FLG_USE_SWAL) {
          Swal.fire({ icon: 'error', text: Message })
          throw new Error(Message)
        } else {
          setStPgMsg(comp_profile('Error saving profile data. Please try again..'));
          setStPgHasErr(true);
        }
      }
    } else {//if(!hasValidInputs) {
      setStPgHasErr(true);
      profSaveResp = false;
    }console.log('profSaveResp', profSaveResp);
    setTimeout(fResetProcessing, 3000);//setStProcessing(false);
    return profSaveResp;
  };
  //---------------------------------------------------------

  let pgMsg = null, aUsrProfilePicUrl = objProfData?.profile_pic_url ||
    DEF_PROFILE_PIC_URL;//|| aUData?.details?.profilePic|| ;
  //if(aUsrProfilePicUrl.endsWith('')) { aUsrProfilePicUrl = DEF_PROFILE_PIC_URL; }

  if(stPgMsg) {
    pgMsg = (
      <Flasher resetCallback={fResetProcessing}>
        <PageMessage text={stPgMsg} type={stPgHasErr ? 'err' : 'info'} />
      </Flasher>
    );
  }

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );
  //---------------------------------------------------------

  return (//isPageLoading && !stProcessing ? loaderJsx ://(<LottieSpinner />) :
    <div id="ctnFrmProf">
      <form> 
        <div className="row">
          <div className="col-md-2">
            <ProfilePicSetter isDisabled={stDisableForm} imgSrc={aUsrProfilePicUrl}
              imgElId='imProfilePic' fileElId='iFPImg' fCallback={fHandleProfilePicChange} />
          </div>
          <div className="col">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                {comp_profile('first_name')}
              </label>
              <div className="col-sm-10">
                <input type="text" readOnly disabled//={stDisableForm}
                  {...fRegFrmProfile('FirstName')}
                  className={`form-control ${Boolean(errFrmProfile.FirstName) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.FirstName && <label className="text-danger">{error_message(errFrmProfile.FirstName.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                {comp_profile('middle_name')}
              </label>
              <div className="col-sm-10">
                <input type="text" readOnly disabled//={stDisableForm}
                  {...fRegFrmProfile('MiddleName')}
                  className={`form-control ${Boolean(errFrmProfile.MiddleName) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.MiddleName && <label className="text-danger">{error_message(errFrmProfile.MiddleName.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                {comp_profile('last_name')}
              </label>
              <div className="col-sm-10">
                <input type="text" readOnly disabled//={stDisableForm}
                  {...fRegFrmProfile('LastName')}
                  className={`form-control ${Boolean(errFrmProfile.LastName) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.LastName && <label className="text-danger">{error_message(errFrmProfile.LastName.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="email-input" className="col-sm-2 col-form-label">
                {comp_profile('email')}
              </label>
              <div className="col-sm-10">
                <input type="email" readOnly disabled={true}
                  className="form-control"
                  name="email" //value={objProfData?.user?.email}
                  defaultValue={objProfData?.user?.email}
                />
              </div>
            </div>
            {/*<div className="form-group row">
              <label htmlFor="example-tel-input"
                className="col-sm-2 col-form-label text-right"
              > Facebook </label>
              <div className="col-sm-10">
                <input type="tel" disabled={stDisableForm}
                  className="form-control"                  
                  name="facebook" value={objProfData.facebook}
                  onChange={handleInputChange.bind(this)}
                />
              </div>
            </div>*/}
            <div className="form-group row">
              <label htmlFor="example-tel-input" className="col-sm-2 col-form-label">
                {comp_profile('contact_info')}
              </label>
              <div className="col-sm-10">
                <PhoneInput
                  disabled={stDisableForm}
                  required
                  className={`form-control ${Boolean(errFrmProfile.contactNum) ? 'is-invalid' : ''}`}
                  id='contactNum'
                  name="contactNum"
                  placeholder="(xxx) xxx-xxxx"
                  value={objProfData?.user?.phone || ''}
                  defaultCountry={defCountryCode}
                  international={false}
                  onChange={(val) => {
                    if (val && typeof val === 'string') {
                      fSetFrmProfileVal('contactNum', val);
                      clearErrors('contactNum');
                    }
                  }}
                  onBlur={(e) => {
                    const val = e.target.value;
                    if (val && typeof val === 'string') {
                      clearErrors('contactNum');
                    } else {
                      setError('contactNum', { type: 'manual', message: error_message('Invalid phone number') });
                    }
                  }}
                />
                {errFrmProfile.contactNum && <label className="text-danger">{error_message(errFrmProfile.contactNum.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('address_line_1')}
              </label>
              <div className="col-sm-10">
                <input ref={addrRef}
                  disabled={stDisableForm} type="text"
                  //{...fRegFrmProfile('Address1')}
                  name="Address1"
                  id="elAddr1"
                  defaultValue={aUserAddressData?.address_line_1 || ''}
                  className={`form-control ${Boolean(errFrmProfile.Address1) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.Address1 && <label className="text-danger">{error_message(errFrmProfile.Address1.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('address_line_2')}
              </label>
              <div className="col-sm-10">
                <input disabled={stDisableForm} type="text"
                  {...fRegFrmProfile('Address2')}
                  id="elAddr2"
                  className={`form-control ${Boolean(errFrmProfile.Address2) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.Address2 && <label className="text-danger">{error_message(errFrmProfile.Address2.message)}</label>}
              </div>
            </div>
            {/*profileAddrField*/}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">{comp_profile('city')}</label>
              <div className="col-sm-4">
                <input disabled={stDisableForm} type="text"
                  {...fRegFrmProfile('City')}
                  id="elCity"
                  //value={aUserAddressData?.city || ''}
                  className={`form-control ${Boolean(errFrmProfile.City) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.City && <label className="text-danger">{error_message(errFrmProfile.City.message)}</label>}
              </div>
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('state_province')}
              </label>
              <div className="col-sm-4">
                {//countryProvinceList.length > 0 ? (
                  arrStates.length > 0 ? (
                  <select
                    disabled={stDisableForm}
                    {...fRegFrmProfile('Province')}
                    id="selProvince"
                    className={`form-control ${Boolean(errFrmProfile.Province) ? 'is-invalid' : ''}`}
                  >
                    <option value={''} data-scode={''} data-ptzid=''>Select</option>
                    {arrStates.map((province, index) => (
                      <option value={province.id} key={index}
                        data-scode={province?.code.toLowerCase()}
                        data-ptzid={province?.primary_timezone_id}
                      >{province.name}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    disabled={stDisableForm} type="text"
                    {...fRegFrmProfile('Province')}
                    id="selProvince"
                    className={`form-control ${Boolean(errFrmProfile.Province) ? 'is-invalid' : ''}`}
                  />
                )}
                {errFrmProfile.Province && <label className="text-danger">{error_message(errFrmProfile.Province.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('zip_code')}
              </label>
              <div className="col-sm-4">
                <input disabled={stDisableForm} type="text"
                  {...fRegFrmProfile('ZIPCode')}
                  id="elZip"
                  className={`form-control ${Boolean(errFrmProfile.ZIPCode) ? 'is-invalid' : ''}`}
                />
                {errFrmProfile.ZIPCode && <label className="text-danger">{error_message(errFrmProfile.ZIPCode.message)}</label>}
              </div>
              <label className="col-sm-2 col-form-label ">{comp_profile('country')}</label>
              <div className="col-sm-4">
                <input disabled//={stDisableForm}
                  readOnly type="text"
                  defaultValue={defCountryName}
                  className={`form-control ${Boolean(errFrmProfile.CountryID) ? 'is-invalid' : ''}`}
                />
                <input type='hidden'// name="country_id"
                  id='country_id'
                  {...fRegFrmProfile('CountryID')}
                  value={defCountryId} />
                {/*{(() => {
                    //fSetFrmProfileVal('CountryID', defCountryId)
                  })()
                }*/}
                {/*<select
                  disabled={stDisableForm}
                  name="country_id"
                  value={profile.country_id}
                  className={`form-control ${Boolean(errFrmProfile.CountryID) ? 'is-invalid' : ''}`}
                  onChange={(e) => {
                    fSetFrmProfileVal('CountryID', e.target.value)
                    fTriggerFrmProfile('CountryID')
                    handleInputChange(e)
                  }}
                >
                  <option value={''}>Select</option>
                  {arrCountries.map((country, index) => (
                    <option value={country.id} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>*/}
                {errFrmProfile.CountryID && <label className="text-danger">{error_message(errFrmProfile.CountryID.message)}</label>}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                {comp_profile('local_timezone')}
              </label>
              <div className="col-sm-4">
                {flgUseTimezoneScheme2? (
                  <TimeZoneSelect2
                    ddId='selTZ' required={true}
                    regFx={fRegFrmProfile}
                    elName='LocalTimeZone'
                    disabled={stDisableForm}
                    tzOptions={arrTz}
                    filterCountryCode={defCountryCode}
                    elStyle = {{marginLeft: '10px', marginRight: '10px', maxWidth: 400}}
                    className={`form-control ${Boolean(errFrmProfile.LocalTimeZone) ? 'is-invalid' : ''}`}
                    /*setTimeZone={(selected) => {console.log(selected);
                      //setProfile((prev) => ({
                      //  ...prev,
                      //  local_time_zone: selected || '',
                      //}))
                      //fSetFrmProfileVal('LocalTimeZone', selected)
                      //fTriggerFrmProfile('LocalTimeZone')
                    }}*/
                  />
                ) : (<></>)}
                {errFrmProfile.LocalTimeZone && <label className="text-danger">{error_message(errFrmProfile.LocalTimeZone.message)}</label>}
              </div>
              <label htmlFor="example-date-input" className="col-sm-2 col-form-label text-right">
                {comp_profile('date_of_birth')}
              </label>
              <div className="col-sm-4">
                <input ref={dobRef}
                  readOnly disabled={stDisableForm}
                  //onkeydown="false"
                  required={true}
                  placeholder={'mm-dd-yyyy'}
                  id={elDatePickerId}
                  {...fRegFrmProfile('DateOfBirth')}
                  name={elDatePickerId}
                  className={`form-control ${Boolean(errFrmProfile.DateOfBirth) ? 'is-invalid' : ''}`}
                  style={{backgroundColor: '#fff'}}
                  //onChangeCapture={(e) => {
                  /*onSelect={(e) => {
                    const inDate = e.target.value;
                    //e.target.value = '';
                    const outDate = moment(inDate).format('YYYY-MM-DD');
                    console.log('Dttt', {inDate, outDate}, e.target);
                    fSetFrmProfileVal('DateOfBirth', outDate)
                    fTriggerFrmProfile('DateOfBirth')
                    handleInputChange(e)
                  }}*/
                />
                {
                  (()=>{//$("#datepicker").val(date);
                      let dobCurrVal = fGetFrmProfileVal();
                      dobCurrVal = dobCurrVal?.DateOfBirth;
                      if(!dobCurrVal) { dobCurrVal = aUserDob; }
                      if(dobCurrVal && dobCurrVal !== dummyDob) {
                        const objDobDt =  new Date(dobCurrVal);
                        setTimeout(()=> {
                          $('#'+elDatePickerId).datepicker('setDate', new Date(objDobDt));
                        }, 20);
                      }
                  })()
                }
                {/*<DateSelector elId={'date_of_birth2'} />*/}
                {errFrmProfile.DateOfBirth && <label className="text-danger">{error_message(errFrmProfile.DateOfBirth.message)}</label>}
              </div>
            </div>
            {/*<div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('language')}
              </label>
              <div className="col-sm-4">
                <select
                  disabled={stDisableForm}
                  name="language"
                  value={profile.language}
                  className={`form-control ${Boolean(errFrmProfile.Language) ? 'is-invalid' : ''}`}
                  onChange={(e) => {
                    fSetFrmProfileVal('Language', e.target.value)
                    fTriggerFrmProfile('Language')
                    handleInputChange(e)
                  }}
                >
                  {languageOptions.map((option) => (
                    <option value={option.value} key={option.value}>
                      <img src={option.flagIcon} alt={option.label} className="flag-icon" /> {option.label}
                    </option>
                  ))}
                </select>
                {errFrmProfile.Language && <label className="text-danger">{error_message(errFrmProfile.Language.message)}</label>}
              </div>
            </div>*/}
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                {comp_profile('language')}
              </label>
              <div className="col-sm-4">
                <LanguageDropdown
                  isDisabled={stDisableForm}
                  arrLanguage={arrLangs}
                  value={objProfData?.language?.language_name}
                  onChange={fHandleLanguageChange}
                  /*onChange={(e) => {//objProfData?.language?.id | language_code
                    //fSetFrmProfileVal('Language', selectedOption.value)
                    //fSetFrmProfileVal('Language', e.target.value)
                    //fTriggerFrmProfile('Language')
                  }}*/
                  error={Boolean(errFrmProfile.Language)}
                />
                {errFrmProfile.Language && <label className="text-danger">{error_message(errFrmProfile.Language.message)}</label>}
              </div>
            </div>
          </div>
        </div>
        <div className="padS dFlexC xtBdr2">
          <div>&nbsp;</div>
          <div className="xtBdr2">
            <div className="xdFlexC xtBdr2">{pgMsg}</div>
          </div>
          <div className="txAC xtBdr2">
            {flgEditable || !stDisableForm ? (
              //stProcessing ? (<RingLoading size={40} />) :
              (
                <button type="button" disabled={stProcessing}
                  className={"btn btn-success btn-round waves-effect waves-light"}
                  style={{ marginRight: '10px' }} onClick={fHandleProfileSubmit}
                >
                  {comp_profile('submit_button')}
                </button>
              )
            ): null}
            {/*false ? (//stDisableForm ? (
              <button style={{ marginRight: '10px' }} onClick={() => setStDisableForm((prev) => !prev)} type="button" className="btn btn-success btn-round waves-effect waves-light">
                {comp_profile('edit_profile_button')}
              </button>
            ) : null}
            {false ? (//!stDisableForm ? (
              <button type="button" className="btn btn-danger btn-round waves-effect waves-light" onClick={handleCancelEdit}>
                {comp_profile('cancel_button')}
              </button>
            ) : null*/}
          </div>
        </div>
      </form>
    </div>
  );
};
