import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from 'v2/src/lib/i18n';
import useAuth from 'hooks/useAuth';
import LoginSchema from 'v2/src/schema/login';
import { APP_ENV, DEF_LANGUAGE_ID, DEF_COUNTRY_ID, USER_TYPES,
} from 'v2/src/config/appConfig';
import { BtnOKCls } from 'v2/src/config/uiConfig';
import { fGetAUserInf, fGetLocalSt, fSetLocalSt,
  fPostAuthSetData, //fSetAuthCookie,
} from 'v2/src/lib/appUtils';
import { AppLogo, LanguageDropdown, } from 'v2/src/core';
//import AuthLoader from './AuthLoader';
import { useLoginMutation } from 'v2/src/lib/services/auth/authApi';
import { rSetUserCredentials, rSetUserDetails, aSGetAuthUserData,
} from 'v2/src/lib/services/auth/authSlice';
import { useLazyUserDetailsQuery } from 'v2/src/lib/services/profileApi';
import shieldImage from 'assets/images/shield.jpg';
//---------------------------------------------------------

export default function Login() {
  //let rsAUData = useSelector(state => state.auth);
  //let rsAUData = fGetAUserInf();//console.log('rsAUDataLogin', { rsAUData });
  //console.log('', useSelector(aSGetAuthUserData));

  const { t: error_message } = useTranslation('error_message');
  const { t: common } = useTranslation('common');
  const { t: authentication } = useTranslation('authentication');
  const languageCookieName = 'niuHLnId';
  const countryCookieName = 'niuHCId';
  //const selLnId = fGetLocalSt(languageCookieName) || DEF_LANGUAGE_ID;
  //const selCountryId = fGetLocalSt(countryCookieName) || DEF_COUNTRY_ID;

  const fDispatch = useDispatch();
  const fNavigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [stShowPassword, setStShowPassword] = useState(false);
  const [stPageWait, setStPageWait] = useState(false);
  const [fLogin, //{ isSuccess: pLoginSuccess, /*isLoading: pWaitLogin, }
  ] = useLoginMutation();
  const [fGetUserDetails] = useLazyUserDetailsQuery({ profileId: null, userType: null });

  const { register, handleSubmit, watch, //getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(LoginSchema), });
  const watchFields = watch(['Password']);

  const fLoginHandler = useCallback(async (data) => {
    //const frmInputs = new FormData();
    //frmInputs.append('email', data.Email);
    let signInResp, profileResp, axCode, strMsg='';
    //console.log('signInData', data);return false;
    const inParams = { email: data?.Email, password: data?.Password };
    //const inParams = { email: 'tamuhoner.salivuji@rungel.net', password: 'Demo@12345' };
    setStPageWait(true);
    //fSetLocalSt(languageCookieName, data? || DEF_LANGUAGE_ID);
    //fSetLocalSt(countryCookieName, data? || DEF_COUNTRY_ID);

    //try {
      signInResp = await fLogin(inParams);//.unwrap();
    /*} catch(excp) {console.log('loginErr', excp);
      if(excp?.status) {
        axCode = excp.status;
      }// finally { fResetAx() }
    }*/

    if(signInResp?.error) { signInResp = signInResp.error; }
    else if(signInResp?.data) { signInResp = signInResp.data; }
    //console.log('signInResp', signInResp);

    if(signInResp?.data?.user?.id) {//signInResp?.status === true
      await fPostAuthSetData(signInResp?.data, fDispatch, rSetUserCredentials,
        fGetUserDetails, rSetUserDetails, setAuth, auth);
      /*fDispatch(rSetUserCredentials(signInResp?.data));
      //fSetLocalSt()
      fSetAuthCookie(signInResp.data, setAuth, auth);
      strMsg = signInResp?.message;

      const authUserId = signInResp?.data?.user?.id;
      //console.log('authUserId', authUserId);
      if(authUserId) {
        profileResp = await fFetchUserProfileData(authUserId);
      }//console.log('profileResp', profileResp);
      */

      fNavigate('/', { replace: true });//'/home', { replace: true });
    } else {
      if(!signInResp?.data?.status) {
        strMsg = signInResp?.message;
        axCode = signInResp?.status;

        if(axCode == '500b') {
          Swal.fire({
            imageUrl: shieldImage, imageWidth: 200, imageHeight: 200,
            html: '<h3>Time to change your password</h3>' +
              authentication('signin_' + axCode) + 
              '<br /><br /><a href="forgot-password" class="'+BtnOKCls +
              '" style="display: inline-block; color: white; text-decoration: none;">Reset Password</a>',
            showConfirmButton: false
          });
          return;
        } else {
          Swal.fire({ icon: 'error', html: authentication('signin_' + axCode) });
          return;
        }
      } else {
        Swal.fire({ icon: 'error', html: common('something_went_wrong') });
        return;
      }
    }
    setTimeout(()=> {setStPageWait(false);}, 1000);
  }, []);
  //---------------------------------------------------------

    return (
      <div className="account-body accountbg">
        <div className="container">
          <div className="row vh-100 ">
            <div className="col-12 align-self-center">
              <div className="auth-page">
                <div className="card auth-card shadow-lg ">
                  <div className="card-body">
                    <div className="px-3">
                      <div style={{ textAlign: 'center' }}>
                        {/*<AppLogo style={{ maxHeight: '100px', height: 'auto', width: '100%', maxWidth: '170px' }} />*/}
                        <AppLogo style={{ maxHeight: '100px', height: '80px', width: 'auto', maxWidth: '100px' }} />
                      </div>

                      <div className="text-center auth-logo-text">
                        <h4 className="mt-0 mb-3 mt-5">{authentication('lets_get_started')}</h4>
                        <p className="text-muted mb-0">{authentication('signin_to_continue')}</p>
                      </div>

                      <form className="form-horizontal auth-form my-4"
                        onSubmit={handleSubmit(fLoginHandler)}
                      >
                        <div className="form-group">
                          <label htmlFor="username">{common('email')}</label>
                          <div className="input-group">
                            <span className="auth-form-icon">
                              <i className="mdi mdi-email"></i>
                            </span>
                            <input type="text" id="username" placeholder={common('enter_email')} {...register('Email')} className={`form-control ${Boolean(errors.Email) ? 'is-invalid' : ''}`} />
                          </div>
                          {errors.Email && <label className="text-danger">{error_message(errors.Email.message)}</label>}
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">{common('password')}</label>
                          <div className="input-group">
                            <span
                              className="auth-form-icon password-eye-icon"
                              onClick={(e) => {
                                setStShowPassword(!stShowPassword)
                              }}
                            >
                              <i className={`mdi ${stShowPassword ? 'mdi-eye' : 'mdi-eye-off'}`}></i>
                            </span>
                            <input
                              type={`${stShowPassword ? 'text' : 'password'}`}
                              id="password"
                              placeholder={common('enter_password')}
                              {...register('Password')}
                              className={`form-control ${Boolean(errors.Password) ? 'is-invalid' : ''}`}
                            />
                          </div>
                          {errors.Password && <label className="text-danger">{error_message(errors.Password.message)}</label>}
                        </div>

                        <div className="form-group row mt-4">
                          <div className="col-sm-6">
                            <div className="hide custom-control custom-switch switch-success">
                              <input type="checkbox" className="custom-control-input" id="customSwitchSuccess" />
                              <label className="custom-control-label text-muted" htmlFor="customSwitchSuccess">
                                {authentication('remember_me')}
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-6 text-right">
                            <Link className="text-muted font-13" to={'/forgot-password'}>
                              <i className="dripicons-lock"></i> {authentication('forgot_password')}
                            </Link>
                          </div>
                        </div>

                        <div className="form-group mb-0 row">
                          <div className="col-12 mt-2">
                            <button type="submit"
                              className={ BtnOKCls + (stPageWait ? ' disabled' : '')}
                            >
                              {stPageWait ? authentication('logging_in') : authentication('login')} <i className="fas fa-loading-alt ml-1"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="m-3 text-center text-muted">
                      <p className="">
                        {authentication('dont_have_account')}{' '}
                        <Link to={'/register'} className="text-primary ml-2">
                          {authentication('create_account')}
                        </Link>
                      </p>
                    </div>

                    <div className="form-group mb-3 row justify-content-center">
                      <div className="col-8 mt-2">
                        <LanguageDropdown
                          onChange={(selectedOption) => {
                            i18n.changeLanguage(selectedOption.value)
                          }}
                          value={i18n.language}
                          dropup={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="account-social text-center mt-4">
                  <h6 className="my-4">{authentication('or_login_with')}</h6>
                  <ul className="list-inline mb-4">
                    <li className="list-inline-item">
                        <a href="" className="">
                          <i className="fab fa-facebook-f facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="" className="">
                          <i className="fab fa-twitter twitter"></i>
                        </a>
                      </li>
                    <li className="list-inline-item">
                      <a
                        href="https://app-niuhealth.auth.us-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://app.niuhealth.com/niuhealth/cburl&response_type=CODE&client_id=3hu5d3btuvetk1avo9ob0i4r0a&scope=email+openid+profile"
                        className=""
                      >
                        <i className="fab fa-google google"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* {APP_ENV !== 'local' && window.addTawkTo()} */}
      </div>
    );
  };
